import React, {useEffect, useState, Suspense} from 'react';
import {Button, Grid, Stack} from "@mui/material";
import {client} from "../../../lib/Client";
import {Loading} from "../../Loading";
import CartEmpty from "../CartEmpty";
import CartSummary from "../CartSummary";
import CartItem from "../CartItem";
import {isCartValid, post, refreshCart} from "../../../lib/utils";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DiscountCode from "../DiscountCode";
import Invoice from "../Invoice";
import {useTranslation} from "react-i18next";
import CartShipping from "../CartShipping";
import {ProductsGroup} from "../../Store/ProductsGroup";
import {useSelector} from "react-redux";
import CartPayment from "../CartPayment";
import {Link, useLinkClickHandler, useNavigate} from "react-router-dom";
import CartItems from "../CartItems";


export const Cart = () => {

    const {loading, cart} = useSelector((state) => state.cart)

    const {t} = useTranslation();
    const navigate = useNavigate();


    const updateCart = (data) => {
        return client.updateCart(cart.id, data).then((response) => {
            refreshCart();
        })
    }

    const removeItem = (itemId) => {
        return client.removeCartItem(cart.id, itemId).then((response) => {
            refreshCart();
        })
    }

    const patchItem = (itemId, data) => {
        return client.patchCartItem(cart.id, itemId, data).then((response) => {
            refreshCart();
        })
    }

    const createDiscountCode = (discountCode) => {
        return client.createDiscountCode(cart.id, discountCode).then((response) => {
            refreshCart();
        })
    }

    const deleteDiscountCode = (discountCodeId) => {
        return client.deleteDiscountCode(cart.id, discountCodeId).then((response) => {
            refreshCart();
        })
    }

    const path = t('routes.checkout')
    const handleSubmit = useLinkClickHandler(path);
    // const handleSubmit = (event) => {
    //     const path = t('routes.checkout')
    //     navigate(path, );
    // }

    const isSubmitDisabled = () => {
        return !isCartValid(cart);
    }
    if (loading) {
        return <>
            <Loading/>
            <ProductsGroup group="cart"/>
        </>
    }

    if (typeof cart.id == "undefined" || cart.items.length === 0) {
        return <>
            <CartEmpty/>
            <ProductsGroup group="cart"/>
        </>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <CartItems cart={cart} removeItem={removeItem} patchItem={patchItem}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                    <CartShipping cart={cart} updateCart={updateCart}/>
                    <DiscountCode cart={cart} createDiscountCode={createDiscountCode}
                                  deleteDiscountCode={deleteDiscountCode}/>


                    <Invoice cart={cart} updateCart={updateCart}/>
                    <CartPayment cart={cart} updateCart={updateCart}/>

                    <CartSummary cart={cart}/>
                    <Button disabled={isSubmitDisabled()} size="large" variant="contained" fullWidth={true}
                            onClick={handleSubmit}
                            endIcon={<ChevronRightIcon/>}>{t('cart.next')}</Button>
                    <Link to="/zamawiam"/>
                </Stack>
            </Grid>
        </Grid>
        <ProductsGroup group="cart"/>
    </>

}

export default Cart
