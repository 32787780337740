import {
    Box,
    Button, Checkbox, CircularProgress, Collapse,
    FormControl, FormControlLabel, FormHelperText, FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField, Typography
} from "@mui/material";
import TextInput from "../../Base/TextInput";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {post} from "../../../lib/utils";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useSelector} from "react-redux";
import {MuiTelInput} from "mui-tel-input";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Introduce = ({formik}) => {
    const {t} = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);
    const {loading, settings} = useSelector((state) => state.settings)

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handlePhoneNumberChange = (value) => {
        formik.setFieldValue("phoneNumber", value, true);
    }
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        setVisible(!visible);
    }
    const all = formik.values.processing && formik.values.consent;
    const allError = (formik.touched.processing && Boolean(formik.errors.processing)) || (formik.touched.consent && Boolean(formik.errors.consent))
    const handleAll = (event, value) => {
        formik.setFieldValue("processing", value, false);
        formik.setFieldValue("consent", value, false);
        formik.validateField("processing");
        formik.validateField("consent");
    }
    const eye = <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
        >
            {showPassword ? <VisibilityOff/> : <Visibility/>}
        </IconButton>
    </InputAdornment>

    if (loading) {
        return <></>
    }


    return <>

        <Typography variant="h5" align="center" sx={{mb: 3}}>{t('user.createAccount')}</Typography>

        <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
            <Grid item xs={6}>
                <TextField
                    variant="outlined" fullWidth
                    label={t('user.firstName')}
                    placeholder={t("user.firstNamePlaceholder")}
                    name="firstName"
                    type="text"
                    autoComplete="nope"
                    required
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="outlined" fullWidth
                    label={t('user.lastName')}
                    placeholder={t("user.lastNamePlaceholder")}
                    name="lastName"
                    type="text"
                    autoComplete="nope"
                    required
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined" fullWidth
                    label={t('user.username')}
                    placeholder={t("user.usernamePlaceholder")}
                    name="email"
                    type="text"
                    autoComplete="nope"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </Grid>
            <Grid item xs={12}>

                <TextField
                    variant="outlined" fullWidth
                    label={t('user.password')}
                    placeholder={t("user.passwordPlaceholder")}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    InputProps={{
                        endAdornment: eye
                    }}
                    autoComplete="nope"
                    required

                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />

            </Grid>
            <Grid item xs={12}>

                <MuiTelInput
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    label={t("user.phoneNumber")}
                    fullWidth
                    name="phone_number"
                    variant="outlined"
                    onChange={handlePhoneNumberChange}
                    value={formik.values.phoneNumber}
                    langOfCountryName={t('locale')}
                    preferredCountries={['PL', 'GB', 'UA', 'CZ', 'SK', 'NO', 'SE']}
                    defaultCountry={'PL'}
                    autoComplete="nope"
                    forceCallingCode
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl
                    required
                    error={allError}
                    variant="outlined"
                    fullWidth={true}
                >
                    <Box sx={{flexDirection: "row", justifyContent: "space-between"}} component="div">
                        <FormControlLabel
                            control={<Checkbox
                                checked={all}
                                onChange={handleAll}
                            />}
                            disableTypography={true}
                            label={<>
                                <Typography
                                    sx={{fontSize: "0.75rem"}}
                                    color={allError ? "error" : null}
                                >{t('acceptAllConsents')}</Typography>
                            </>}
                        />
                        <Button
                            sx={{float: "right", fontSize: "0.75rem"}}
                            variant="text"
                            onClick={toggleVisible}
                            endIcon={<KeyboardArrowDownIcon sx={{
                                transition: "transform 300ms",
                                transform: visible ? "rotate(180deg)" : "rotate(0deg)"
                            }}/>}
                        >{t(visible ? 'collapse' : 'expand')}</Button>
                    </Box>
                    {
                        allError && <FormHelperText>{t('validation.consent')}</FormHelperText>
                    }
                </FormControl>

            </Grid>
            <Grid item xs={12}>
                <Collapse in={visible}>
                    <FormControl
                        required
                        error={formik.touched.processing && Boolean(formik.errors.processing)}
                        variant="outlined"
                    >
                        <FormControlLabel
                            control={<Checkbox
                                checked={formik.values.processing}
                                name="processing"
                                onChange={formik.handleChange}
                            />}
                            label={<Typography
                                sx={{fontSize: "0.75rem"}}
                                color={formik.touched.processing && formik.errors.processing ? "error" : null}
                                dangerouslySetInnerHTML={{__html: settings.other.register_processing}}/>}
                        />
                        {formik.touched.processing && formik.errors.processing &&
                        <FormHelperText>{formik.errors.processing}</FormHelperText>}
                    </FormControl>

                    <FormControl
                        required
                        error={formik.touched.consent && Boolean(formik.errors.consent)}
                        variant="outlined"
                    >
                        <FormControlLabel
                            control={<Checkbox
                                checked={formik.values.consent}
                                name="consent"
                                onChange={formik.handleChange}
                            />}
                            label={<Typography
                                sx={{fontSize: "0.75rem"}}
                                color={formik.touched.consent && formik.errors.consent ? "error" : null}
                                dangerouslySetInnerHTML={{__html: settings.other.register_consent}}/>}
                        />
                        {formik.touched.consent && formik.errors.consent &&
                        <FormHelperText>{formik.errors.consent}</FormHelperText>}
                    </FormControl>

                </Collapse>
            </Grid>
        </Grid>
        <Button endIcon={formik.isSubmitting ? <CircularProgress size="1em"/> : <KeyboardArrowRightIcon/>}
                disabled={formik.isSubmitting} variant="contained" fullWidth={true} onClick={formik.handleSubmit}
                sx={{mt: 3}}>
            {t('next')}
        </Button>
    </>
}

export default Introduce;