import {styled} from "@mui/material";
import {Clock as ClockBase} from "react-clock"

const Clock = styled(ClockBase)(({theme}) => ({
    '.react-clock__hand__body': {
        backgroundColor: theme.palette.primary.main,
    },
    '.react-clock__face': {
        borderColor: theme.palette.background.paper,
        //backgroundColor: theme.palette.background.paper,
    },
    '.react-clock__mark__body': {
        backgroundColor: theme.palette.background.default,
    }
}));


export default Clock;