
import React from "react";
import {useSelector} from "react-redux";
import {Loading} from "../../Loading";
import {useTranslation} from "react-i18next";


const UpcomingEvent = () => {
    const {t} = useTranslation();
    const {loading, event} = useSelector((state) => state.upcomingEvent)

    if (loading) {
        return <Loading/>
    }
    const locale = t('locale');
    const url = `${t('routes.events')}/${event.slug}`

    let date = '';
    const options = { day: 'numeric', month: 'long', year: "numeric" };
    const fullDate = new Date(event.date)
    if (event.date_to) {
        const fullDateTo = new Date(event.date_to);
        date = fullDate.toLocaleDateString(locale, {day: "numeric"});
        date += " - " + fullDateTo.toLocaleDateString(locale, options);
    } else {
        const fullDate = new Date(event.date)
        date = fullDate.toLocaleDateString(locale, options);
    }

    return (
    <div className="upcoming-event-container" style={{margin: "0 auto", "padding":0}}>
        <div className="upcoming-event" style={{margin: "-10px auto", backgroundImage: `url(${event.image})`}}>
            <div className="upcoming-event-name">{event.name} {event.place}<br/><span
                className="upcoming-event-container-date">{date}</span>
            </div>
            <div className="upcoming-event-btn">
                <a className="btn btn-xl" href={url}>{t('signUp')} <i className="fas fa-angle-right"></i></a>
            </div>
        </div>
    </div>
    )
}

export default UpcomingEvent;