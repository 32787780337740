import React, {createElement, Suspense, useEffect, useState} from 'react';
import {Loading} from "../Loading";
import {ThemeProvider} from "@mui/material";
import {render, unmountComponentAtNode} from "react-dom";
import {client} from "../../lib/Client";
import InsuranceProduct from "./InsuranceProduct";
import {theme} from "../../lib/theme";



const Insurance = ({event}) => {
    const [interested, setInterested] = useState(false);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [itemsTraining, setItemsTraining] = useState([]);

    const [chosen, setChosen] = useState([]);
    const [chosenTraining, setChosenTraining] = useState([]);
    // kid, series, pack
    const [series, setSeries] = useState(null);
    const [kid, setKid] = useState(null);
    const [withoutShirt, setWithoutShirt] = useState(null);




    useEffect(() => {
        if (!interested) {
            setItems([]);
            return;
        }
        setLoading(true);

        const first = client.insuranceEventQuotes(event, series, kid, withoutShirt).then(response => {
                setItems(response.data.quotes);
            }, error => {
                console.log(error);
            });
        const second = client.insuranceTrainingQuotes(kid).then(response => {
            setItemsTraining(response.data.quotes);
        }, error => {
            console.log(error);
        });
        Promise.all([first, second]).then(() => {
            setLoading(false);
        })


    },[interested, series, withoutShirt, kid]);

    useEffect(() => {
        $("#series").on("change.setSeries", (event) => {
            setSeries(event.target.value);
        });
        return () => {
            $("#series").off("change.setSeries");
        }
    }, []);

    useEffect(() => {
        $("[name=kid]").on("change.setKid", (event) => {
            setKid(event.target.value);
        });
        return () => {
            $("[name=kid]").off("change.setKid");
        }
    }, []);

    useEffect(() => {
        $("[name=withoutStarterPack]").on("change.setWithoutShirt", (event) => {
            setWithoutShirt($("[name=withoutStarterPack]:checked").val());
        });
        return () => {
            $("[name=withoutStarterPack]").off("change.setWithoutShirt");
        }
    }, []);

    function extracted(items) {
        const itemsByProduct = {};
        for (let i in items) {
            let item = items[i]
            if (!(item['product_id'] in itemsByProduct)) {
                itemsByProduct[item['product_id']] = {
                    "id": item['product_id'],
                    "name": item['product_name'],
                    "items": []
                }
            }
            itemsByProduct[item['product_id']]["items"].push(item);
        }
        return Object.values(itemsByProduct);
    }

    const itemsByProduct = extracted(items);
    const itemsTrainingByProduct = extracted(itemsTraining);

    function toggleChosen(value) {
        if(chosen.includes(value)) {
            const new_chosen = chosen.filter(function(ele){
                return ele !== value;
            });
            setChosen(new_chosen);
        } else {
            const new_chosen = chosen.concat([value]);
            setChosen(new_chosen);
        }
    }

    function toggleChosenTraining(value) {
        if(chosenTraining.includes(value)) {
            const new_chosen = chosenTraining.filter(function(ele){
                return ele !== value;
            });
            setChosenTraining(new_chosen);
        } else {
            let new_chosen = chosenTraining
            for (let i in itemsTrainingByProduct) {
                let product = itemsTrainingByProduct[i];
                let item_ids = product.items.map((item) => item.id);
                if (item_ids.includes(value)) {
                    new_chosen = chosenTraining.filter(function(ele){
                        return !item_ids.includes(ele);
                    });
                }
            }
            new_chosen = new_chosen.concat([value]);
            setChosenTraining(new_chosen);
        }
    }

    return (
        <div style={{marginTop: "20px"}}>
            <div>
                <a className={`btn ${interested ? "btn-invert" : ""}`} onClick={() => setInterested(false)}>NIE</a>
                <a className={`btn ${!interested ? "btn-invert" : ""}`} style={{margin: "0 10px 0 20px"}} onClick={() => setInterested(true)}>TAK</a>
            </div>

            { interested && <div>
                {loading && <Loading/>}
                {!loading && <React.Fragment>
                    <div className="insurance-caption">Wybierz ubezpieczenie na zawody</div>
                    <div className="insurance-products">
                    {itemsByProduct.map((product => <InsuranceProduct key={product.id} {...product} input_name="insurance" chosen={chosen} toggle={toggleChosen}/>))}
                    </div>
                    <div className="insurance-caption">lub ubezpiecz się na 12 miesięcy</div>
                    <div className="insurance-products">
                    {itemsTrainingByProduct.map((product => <InsuranceProduct key={product.id} {...product} input_name="insurance_training" chosen={chosenTraining} toggle={toggleChosenTraining}/>))}
                    </div>
                    <InsuranceAgreement/>
                    <div>Partner ubezpieczeniowy UNIQA S.A.</div>
                    <div>Agentem ubezpieczeniowym jest Ubezpieczenia dla Aktywnych Sp. z o.o. (UDAO) agent UNIQA S.A.</div>
                </React.Fragment>}
            </div> }

        </div>
    );
}


function InsuranceAgreement() {
    return <div>
        <div style={{fontSize: "0.75em"}}>
            <div className={"control-group"}>
                <div className="form-check">
                    <input type="hidden" name="insurance_agreement_marketing" value="0"/>
                    <input type="checkbox" name="insurance_agreement_marketing" id="insurance_agreement_marketing" value="1"/>
                    <label htmlFor="insurance_agreement_marketing">Wyrażam zgodę na przetwarzanie danych osobowych przez UNIQA Towarzystwo Ubezpieczeń S.A. w celu otrzymania komunikacji marketingowej drogą mailową. Zgodę można wycofać w dowolnym momencie.<br/>
                        Wycofanie zgody ma skutek tylko na przyszłość i nie ma wpływu na zgodność z prawem dokonanego już przetwarzania,
                    </label>
                </div>
            </div>
        </div>
    </div>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <Insurance {...props}/>
        </Suspense>
    </ThemeProvider>
}


class InsuranceHTMLElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value]);
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-insurance', InsuranceHTMLElement)

export default InsuranceHTMLElement