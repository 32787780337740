import React, {useEffect, useState} from "react";
import 'react-image-lightbox/style.css';
import {useSelector} from "react-redux";

const EventGalleryBanner = ({banner}) => {

    return <div className="gallery-banner-container">
        <div className="gallery-banner">
            <a href={banner.url} target="_blank" rel="noreferrer" title={banner.name}>
                <img src={banner.file} alt={banner.name}/>
            </a>
        </div>
    </div>
}

export default EventGalleryBanner;