import React, {useEffect, useState} from 'react';

const style = {
    display: "flex",
    justifyContent: "center"
}

export function Loading() {
    return <div style={style}>
        <div className={"fa-3x"}>
            <i className={"fas fa-circle-notch fa-spin"}></i>
        </div>
    </div>
}