import React, {useState} from "react";
import axios from "axios";
import {Loading} from "../Loading";

export function FileUpload(props) {
    const [isUploading, setIsUploading] = useState();
    const [errors, setErrors] = useState([]);
    const [uploadedFile, setUploadedFile] = useState();
    const onFileChange = event => {
        const file = event.target.files[0]
        const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );
        setIsUploading(true);
        setErrors([]);
        setUploadedFile(null);
        axios.post( '/api/upload', formData).then(response => {
            setUploadedFile(response.data);
            setIsUploading(false);
        }, error => {
            setIsUploading(false);
            setErrors(error.response.data.errors);
        });
    }
    const fileData = () => {
        if (uploadedFile) {
            return (
                <div>
                    <img src={uploadedFile.thumbnail} alt={uploadedFile.name}/>
                </div>
            );
        } else {
            return ""
        }
    }
    return <div className={"file-upload"} style={{maxWidth: "463px"}}>
        <div><input type={"hidden"} name={props.name} value={uploadedFile?uploadedFile.id:""} readOnly={true}/></div>
        { !isUploading && <div>
            <label htmlFor={"file-upload-"+props.name} className={"btn btn-invert"}><i className={"fas fa-cloud-upload-alt"}></i> Dodaj plik</label>
            <input type="file" id={"file-upload-"+props.name} onChange={onFileChange} style={{display: "none"}}/>
        </div>}
        { isUploading && <Loading/> }
        {fileData()}
        { errors && <span className={'error'}>{errors.map(e => e)}</span> }
    </div>
}