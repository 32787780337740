import React, {useEffect, useState} from 'react';
import {formatMoney} from "../../../lib/utils";
import {
    Button,
    Card,
    Radio,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Typography,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    DialogActions, Dialog, IconButton, Paper, Checkbox, FormHelperText, Collapse
} from "@mui/material";
import {useTranslation, Trans} from 'react-i18next';
import {client} from "../../../lib/Client";
import PaymentChoice from "../PaymentChoice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const CheckoutPayment = ({cart, formik}) => {
    const {t} = useTranslation();
    const [payments, setPayments] = useState([]);
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchPayments = () => {
        if (!cart.payment_required) {
            return;
        }
        setLoading(true);
        return client.payments(cart.total.amount, cart.total.currency.code).then((response) => {
            setPayments(response.data.channels);
            setForms(response.data.forms);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching payments', error)
        });
    }

    useEffect(() => {
        fetchPayments();
    }, []);

    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        setVisible(!visible);
    }
    const all = Object.values(formik.values).reduce((prev, current) => prev && current, true);
    const allError = Object.keys(formik.values).reduce((prev, current) => formik.touched[current] && Boolean(formik.errors[current]), false);
    const handleAll = (event, value) => {
        const values = {}
        for (const key in formik.values) {
            values[key] = value;
        }
        formik.setValues(values);
    }

    let payment = null;
    if (!cart.payment) {
        return <></>
    }
    if (cart.payment && payments.length) {
        payment = payments.filter((item) => item.id.toString() == cart.payment.toString()).pop();
    }
    let agreementForm = null
    if (forms.length) {
        agreementForm = forms.filter((item) => item.form_name === "agreement").pop();
    }

    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.chosenPayment')} {loading && <CircularProgress size="1em"/>}
                </Typography>
                {!loading && <Box sx={{mt: 1, mb: 1}}>
                    {payment !== null && <>
                        <Typography sx={{mb: 1}}>{payment.group_name}: {payment.name}</Typography>
                        <PaymentChoice payment={payment}/>
                    </>}
                    {Object.keys(formik.values).length > 0 && <>
                        <FormControl
                            required
                            error={allError}
                            variant="outlined"
                            fullWidth={true}

                        >
                            <Box sx={{flexDirection: "row", justifyContent: "space-between"}} component="div">
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={all}
                                        onChange={handleAll}
                                    />}
                                    disableTypography={true}
                                    label={<Typography
                                        sx={{fontSize: "0.75rem"}}
                                        color={allError ? "error" : null}
                                    >{t('acceptAllConsents')}</Typography>}
                                />

                                <Button sx={{float: "right", fontSize: "0.75rem"}}
                                        variant="text"
                                        onClick={toggleVisible}
                                        endIcon={<KeyboardArrowDownIcon sx={{
                                            transition: "transform 300ms",
                                            transform: visible ? "rotate(180deg)" : "rotate(0deg)"
                                        }}/>}
                                >{t(visible ? 'collapse' : 'expand')}</Button>
                            </Box>
                            {
                                allError && <FormHelperText>{t('validation.consent')}</FormHelperText>
                            }
                        </FormControl>

                    </>}
                    <Collapse in={visible}>
                        {agreementForm && agreementForm["fields"].filter(item => Object.keys(formik.values).includes(item.name)).map((field) => {
                                const label = field.description_html ?? field.label
                                const name = field.name

                                return <FormControl
                                    key={field.name}
                                    required={field.required}
                                    error={formik.touched[name] && Boolean(formik.errors[name])}
                                    variant="outlined"
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formik.values[name]}
                                            name={name}
                                            onChange={formik.handleChange}
                                        />}
                                        label={<Typography
                                            sx={{fontSize: "0.75rem"}}
                                            color={formik.touched[name] && formik.errors[name] ? "error" : null}
                                            dangerouslySetInnerHTML={{__html: label}}/>}
                                    />
                                    {formik.touched[name] && Boolean(formik.errors[name]) &&
                                    <FormHelperText>{formik.errors[name]}</FormHelperText>}
                                </FormControl>
                            }
                        )}
                    </Collapse>
                </Box>
                }
            </CardContent>
        </Card>
    </React.Fragment>
}

export default CheckoutPayment

