import { Button, Divider, FormControl, InputLabel, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {post} from "../../../lib/utils";
import GoogleIcon from '@mui/icons-material/Google';
import TextInput from "../../Base/TextInput";


const LoginForm = () => {
    const {t} = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const params = new URLSearchParams(document.location.search);
    const redirect = params.get("redirect")

    const handleSubmit = (ev) => {
        let url = t("routes.login");
        if (redirect !== null) {
            url += "?redirect=" + redirect
        } else {
            url += "?redirect=" + window.location.pathname
        }
        post(url, {
            "identity": username,
            "credential": password
        });
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    return <>
        <Typography sx={{textAlign: "center", mb: 3, mt: 3}} dangerouslySetInnerHTML={{__html: t('user.login.intro')}} >
        </Typography>
        <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="login-username">
                {t('user.username')}
            </InputLabel>
            <TextInput id="login-username"
                       placeholder={t("user.username")}
                       value={username}
                       onChange={handleUsernameChange}
                       onKeyDown={e => e.key === 'Enter' ? handleSubmit() : ''}/>
        </FormControl>

        <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="login-password">
                {t("user.password")}
            </InputLabel>
            <TextInput id="login-password"
                placeholder={t("user.password")}
                type="password"
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={e => e.key === 'Enter' ? handleSubmit() : ''}
            />
        </FormControl>
        <FormControl fullWidth sx={{mt: 1, mb: 1}}>
            <Button variant={"contained"} fullWidth={true} onClick={handleSubmit}>
                {t('user.login.login')}
            </Button>
        </FormControl>
        <Divider  sx={{mt: 3, mb: 3}}>{t('user.login.or')}</Divider>
        <FormControl fullWidth sx={{mt: 1, mb: 1}}>
            <Button variant={"outlined"} startIcon={<GoogleIcon/>} fullWidth={true} href={t('routes.loginGoogle')}>
                {t('user.login.loginWithGoogle')}
            </Button>
        </FormControl>
        <Typography sx={{textAlign: "center", mb: 0, mt: 3}}>
            {t('user.forgotPassword')} <a href={t('routes.recover')}> {t('user.recoverPassword')}</a>
        </Typography>
    </>
}
export default LoginForm