import {alpha, InputBase, styled} from "@mui/material";

const TextInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderStyle: 'solid',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.palette.text.secondary,
        position: 'relative',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            borderColor: theme.palette.text.primary
        },
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.primary.contrastText} inset`,
            WebkitTextFillColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        marginBottom: theme.spacing(2)
    },
}));
export default TextInput;