import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import React from 'react';
import {KIDS_SHIRT_SIZES} from "../../../constants";



const KidsShirtSizeSelect = ({name, ...props}) => {

    const [value, setValue] = useState("");

    const handleChange = (ev) => {
        setValue(ev.target.value);
    }
    return <FormControl sx={{ m: 0, minWidth: "240px" }}>
        <Select
            name={name}
            value={value}
            onChange={handleChange}
            displayEmpty={true}
            {...props}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <em>Wybierz rozmiar koszulki</em>;
                }

                return selected;
            }}
        >
            <MenuItem value="">
                <em>Wybierz rozmiar koszulki</em>
            </MenuItem>
            { KIDS_SHIRT_SIZES.map((size) => <MenuItem key={size} value={size}>{size}</MenuItem>) }
        </Select>
        {/*<FormHelperText>With label + helper text</FormHelperText>*/}
    </FormControl>

}


export default KidsShirtSizeSelect;