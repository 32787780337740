import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel, Typography
} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import InvoiceDialog from "../InvoiceDialog";
import {useTranslation} from "react-i18next";


const CheckoutInvoice = ({cart}) => {
    const {t} = useTranslation();

    if (!cart.issue_invoice) {
        return <></>
    }

    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.invoiceData')}
                </Typography>
                <Box sx={{mt: 1, mb: 1}}>
                    {cart.issue_invoice && <React.Fragment>

                    <div>{t('invoice.taxId')}: <b>{cart.invoice_data.tax_id}</b></div>
                    <div>{t('invoice.company')}: <b>{cart.invoice_data.company}</b></div>
                    <div>{cart.invoice_data.address}</div>
                    <div>{cart.invoice_data.zip_code} {cart.invoice_data.town}</div>
                    </React.Fragment>
                    }
                </Box>
            </CardContent>
        </Card>
    </React.Fragment>
}

export default CheckoutInvoice