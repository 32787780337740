import {isCartValid, isObjectEmpty, post} from "../../lib/utils";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Button, CircularProgress, Grid, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import CartSummary from "../../components/Order/CartSummary";
import {ProductsGroup} from "../../components/Store/ProductsGroup";
import {Loading} from "../../components/Loading";
import CartItems from "../../components/Order/CartItems";
import CheckoutPayment from "../../components/Order/CheckoutPayment";
import CheckoutInvoice from "../../components/Order/CheckoutInvoice";
import CheckoutShipping from "../../components/Order/CheckoutShipping";
import * as yup from "yup";
import {useFormik} from "formik";
import {client} from "../../lib/Client";
import {useNavigate} from "react-router-dom";
import {trackCheckout} from "../../lib/tracking";


const Checkout = () => {
    const {loading, cart}  = useSelector((state) => state.cart)
    const {authenticated, user, qrCodeDialog} = useSelector((state) => state.user)

    const {t} = useTranslation();

    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();

    const validationSchema = cart.payment_required ? yup.object({
        bylaw: yup.boolean().oneOf([true], t('validation.consent')),
        personal_data: yup.boolean().oneOf([true], t('validation.consent')),
    }) : yup.object({});

    const formik = useFormik({
        initialValues: {
            bylaw: false,
            personal_data: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setProcessing(true)
            return client.checkout(cart.id, values).then(response => {
                const {order, payment, online_payment} = response.data
                setProcessing(false);

                if (online_payment) {
                    post(online_payment.action, online_payment.data);
                } else {
                    navigate(`/thank-you?status=OK&order_id=${order.id}`);
                }
            }).catch(err => {
                setProcessing(false)
            });
        },
    });

    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        if (!isObjectEmpty(cart) && !isObjectEmpty(user)) {
            try {
                trackCheckout(cart, user);
            } catch (e) {
                console.log(e);
            }
        }
    }, [cart, user]);


    const isSubmitDisabled = () => {
        return !isCartValid(cart);
    }

    if (loading) {
        return <>
            <Loading/>
            <ProductsGroup group="cart"/>
        </>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <CartItems cart={cart}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                    <CheckoutShipping cart={cart}/>
                    <CheckoutInvoice cart={cart}/>
                    <CheckoutPayment cart={cart} formik={formik}/>
                    <CartSummary cart={cart}/>
                    <Button disabled={isSubmitDisabled() || processing}
                            size="large"
                            variant="contained"
                            fullWidth={true}
                            onClick={formik.handleSubmit}
                            endIcon={processing ? <CircularProgress size="1em"/> : <ChevronRightIcon/>}
                    >{t('cart.orderAndPay')}</Button>
                </Stack>
            </Grid>
        </Grid>
    </>
}

export default Checkout;