import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CircularProgress, Divider,
    IconButton,
    InputAdornment, OutlinedInput,
    TextField,
    Typography
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CartItem from "../CartItem";
import {formatMoney} from "../../../lib/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import { toast } from 'react-toastify';



const style = {
    display: "flex",
    justifyContent: "center"
}

const DiscountCode = ({cart, createDiscountCode, deleteDiscountCode}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        setError(false);
        setErrorMessage('');
        setValue(event.target.value);
    };
    const handleApply = (event) => {
        setLoading(true);
        setError(false)
        setErrorMessage('');
        createDiscountCode(value).catch((error) => {
            setLoading(false);
            setError(true);

            if (error.response) {
                let errors = error.response.data["errors"]["discountCode"];
                if (typeof errors !== "object") {
                    errors = Object.values(errors).join(". ");
                } else {
                    errors = Object.values(errors).join(". ");
                }
                setErrorMessage(errors);
            }
        }).then(() => {
            setLoading(false);
        })
    }
    const handleKeyDown = (event) => {
        if (event.key == "enter") {
            handleApply(event);
        }
    }
    const handleDelete = (item) => (event) => {
        setLoading(true);
        deleteDiscountCode(item.id).catch((error) => {
            setLoading(false);
        }).then(() => {
            setLoading(false);
        })
    }

    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.discountCode')}
                </Typography>
                { cart.discount_codes.length === 0 && <React.Fragment>
                    <Typography sx={{mt:1, mb: 1}} component="div">
                        {t('cart.discountCodeDescription')}
                    </Typography>
                    <TextField
                        error={error}
                        helperText={errorMessage}
                        placeholder={t('cart.discountCodePlaceholder')}
                        variant="outlined"
                        value={value}
                        onChange={handleChange}
                        fullWidth={true}
                        onKeyPress={(data) => {
                            if (data.key === 'Enter' && value !== '') {
                                handleApply(data);
                            }
                        }}
                        InputProps={{
                            endAdornment:  <InputAdornment position="end">
                                <Button
                                    variant="text"
                                    endIcon={loading ? <CircularProgress size="1em"/> : <ChevronRightIcon/>}
                                    onClick={handleApply}
                                    edge="end"
                                    disabled={value === ""}
                                    color="primary"
                                >{t('cart.discountCodeApply')}</Button>
                            </InputAdornment>,
                        }}
                    />
                </React.Fragment> }
                {cart.discount_codes.map((item, index) => {
                    return <Typography key={item.id} variant="" component="div">
                                {t('cart.discountCode')}: <b>{item.code}</b>
                                { loading && <CircularProgress size="2rem"/>}
                                { !loading && <IconButton aria-label="delete" color="error" size="small" onClick={handleDelete(item)}>
                                    <DeleteIcon fontSize="inherit"/>
                                </IconButton> }
                            </Typography>
                })}
            </CardContent>
        </Card>
    </React.Fragment>
}

export default DiscountCode