import React, {Component, useEffect, useRef, useState} from "react";
import {client} from "../../../lib/Client";
import {
    Autocomplete,
    Button,
    ButtonGroup,
    CircularProgress,
    createFilterOptions,
    Divider, Stack,
    TextField
} from "@mui/material";
import {debounce} from "debounce";
import {Groups, Person} from "@mui/icons-material";


const filter = createFilterOptions({
    ignoreCase: true
});

export const TeamSelect = ({value, onChange}) => {
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = useState([]);
    const [inTeam, setInTeam] = useState(value !== null && value !== false ? true : value);
    const [teamValue, setTeamValue] = React.useState(value !== null && value !== false ? value : null);


    const fetchTeams = (search) => {
        setLoading(true);
        client.teams(search).then((response) => {
            setOptions(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching teams', error)
        });
    }

    const debouncedFetchTeams = useRef(debounce(fetchTeams, 600));

    useEffect(() => {
        if (inputValue.length === 0) {
            return;
        }
        debouncedFetchTeams.current(inputValue);
    }, [inputValue]);

    const handleClick = (newValue) => (ev) => {
        setInTeam(newValue);
    }

    useEffect(() => {
        let newValue = null;
        if (inTeam === false) {
            newValue = false;
        } else {
            if (teamValue) {
                newValue = teamValue;
            }
        }
        onChange(newValue);
    }, [inTeam, teamValue]);

    return (
        <Stack spacing={2}>
            <ButtonGroup
                orientation="horizontal"
                disableElevation
                size="large"
                fullWidth
            >
                <Button
                    variant={inTeam === false ? "contained" : "outlined"}
                    startIcon={<Person/>}
                    onClick={handleClick(false)}
                >Biegnę sam</Button>
                <Button
                    variant={inTeam === true ? "contained" : "outlined"}
                    onClick={handleClick(true)}
                    endIcon={<Groups/>}
                >Biegnę w drużynie</Button>
            </ButtonGroup>
            {inTeam && <Autocomplete
                autoComplete
                value={teamValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string' && newValue !== '') {
                        const isExisting = options.filter((option) => newValue.toLowerCase() === option.name.toLowerCase());
                        if (isExisting.length === 0) {
                            newValue = {
                                inputValue: newValue,
                                id: null
                            }
                        } else {
                            newValue = {...isExisting[0]}
                        }
                    }
                    if (newValue?.id === null) {
                        newValue.name = newValue.inputValue
                    }
                    setTeamValue(newValue);

                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const {inputValue} = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue.toLowerCase() === option.name.toLowerCase());
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            id: null,
                            name: `Dodaj nową drużynę "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                freeSolo
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name
                }}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                getOptionKey={(option) => option.id}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Wprowadź nazwę drużyny"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            /> }

        </Stack>
    );
}