import {AddToCart} from "../AddToCart";
import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, ThemeProvider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {theme} from "../../../lib/theme";


export const AddToCartProductVariants = ({product_id,variants, onAdd}) => {
    const {t} = useTranslation();
    const [variant, setVariant] = useState("");
    if (typeof(variants) === "string") {
        variants = JSON.parse(variants);
    }

    const handleChange = (event) => {
        setVariant(event.target.value);
    }
    const id = "add-to-cart-variant-" + product_id
    return <React.Fragment>
        <FormControl fullWidth size="small" sx={{mb: 2}}>
            <InputLabel id={id + "-label"}>Wybierz</InputLabel>
            <Select
                labelId={id + "-label"}
                id={id}
                value={variant}
                label="Wybierz"
                onChange={handleChange}
                variant="outlined"
            >
                {Object.keys(variants).map( key => <MenuItem
                    value={key}
                    key={key}>{variants[key]}</MenuItem>)}
            </Select>
        </FormControl>
        <AddToCart fullWidth onAdd={onAdd} disabled={variant === ""} size="large" variant="contained" data={[{type: "product", product_id: product_id, variant_id: variant}]}/>
    </React.Fragment>

}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <AddToCartProductVariants {...props}/>
        </Suspense>
    </ThemeProvider>
}

class AddToCartProductVariantsElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-add-to-cart-product-variants', AddToCartProductVariantsElement)

export default AddToCartProductVariantsElement