
import * as React from 'react';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {client} from "../../../lib/Client";
import {Box, LinearProgress} from "@mui/material";
import CouponList from "../../../components/CouponList";



const UserCoupons = () => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        client.userCoupons().then(response => {
            setCoupons(response.data);
            setLoading(false);
        })
    }, []);
    return (
        <Paper style={{ width: '100%' }}>
            <Box sx={{width: "100%", height: 2}}>
                { loading && <LinearProgress/> }
            </Box>
            { !loading && <CouponList coupons={coupons}></CouponList> }
        </Paper>
    );
}
export default UserCoupons;