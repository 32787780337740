import {ThemeProvider} from "@mui/material";
import {theme} from "../../../lib/theme";
import React, {Suspense, useState} from "react";
import {MuiTelInput} from "mui-tel-input";
import {useTranslation} from "react-i18next";


const TelInput = ({value, name, ...props}) => {
    const {t} = useTranslation();
    const [val, setVal] = useState(value);
    const handlePhoneNumberChange = (value) => {
        setVal(value);
    }
    return <>
        <MuiTelInput
        {...props}
        value={val}
        onChange={handlePhoneNumberChange}

        fullWidth
        variant="outlined"
        langOfCountryName={t('locale')}
        preferredCountries={['PL', 'GB', 'UA', 'CZ', 'SK', 'NO', 'SE']}
        defaultCountry={'PL'}
        forceCallingCode
        />
        <input type="hidden" name={name} value={val} />
    </>
}


export const WrappedTelInput = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <TelInput {...props} />
        </Suspense>
    </ThemeProvider>
}

export default TelInput