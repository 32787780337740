import React from "react";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    return <>
        <div className="index-section-alternate">
            <div className="container">

            </div>
        </div>
    </>
}

export default Index;