import {AddToCart} from "../AddToCart";
import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import KidsShirtSizeSelect from "../../SignUp/KidsShirtSizeSelect";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


export const AddToCartShirt = ({start_id}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    const handleClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (ev, newValue) => {
        setValue(ev.target.value);
    }
    return <>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {t('cart.addToCart')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{mb:2}}>
                    <KidsShirtSizeSelect name="shirt_size" value={value} onChange={handleChange}/>
                </FormControl>
                <AddToCart fullWidth={true} disabled={value === ""} data={[{type: "shirt", start_id: start_id , shirt_size: value}]} onAdd={() => setOpen(false)}/>
            </DialogContent>
        </Dialog>
        <Button size="small" variant="outlined" endIcon={<AddShoppingCartIcon/>} onClick={handleClick}>Dokup koszulkę</Button>
    </>
}

export default AddToCartShirt;