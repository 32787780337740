import React, {useEffect, useState} from 'react';

import ListItemText from '@mui/material/ListItemText';

import {
    Avatar,
    FormControl, FormHelperText,
    InputLabel,
    ListItemAvatar,
    MenuItem,
    Select,

} from "@mui/material";
import {Girl, Boy, Add} from "@mui/icons-material";
import {client} from "../../../lib/Client";
import {calculateAgeAtDate} from "../../../lib/utils";
import {Loading} from "../../Loading";
import KidCreateDialog from "../../KidCreateDialog";
import { useSelector, useDispatch } from 'react-redux'
import {fetchKids} from "../../../features/kids/kidsSlice";

export default function KidsSelect({value, race, label, helperText, error, onChange, name, ...props}) {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [internalValue, setInternalValue] = useState(value)

    const kidsStatus = useSelector(state => state.kids.status)
    const kids = useSelector(state => state.kids.kids)


    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (kidsStatus === 'idle') {
            dispatch(fetchKids())
        }
    }, [kidsStatus, dispatch]);

    const handleChange = (event) => {
        let newValue = parseInt(event.target.value);
        if (newValue === -1) {
            event.target.value = "";
            newValue = ""
        }
        setInternalValue(newValue);
        onChange(event);
    };
    const handleAdded = (kid) => {
        const event = new Event("change");
        const target = {
            type: "text",
            name: name,
            id: undefined,
            value: kid.id,
            checked: undefined,
            outerHTML: undefined,
            options: undefined,
            multiple: undefined
        }
        Object.defineProperty(event, 'target', {writable: false, value: target});
        handleChange(event);
    }

    if (kidsStatus === 'loading') {
        return <>
            <Loading/>
        </>
    }
    const controlProps = {};

    return (
        <FormControl error={error}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                value={internalValue}
                renderValue={(selected) => {
                    if (selected === "") {
                        return <em> - wybierz </em>
                    }
                    const kid = kids.findLast(k => k.id === parseInt(selected));
                    if (kid) {
                        return `${kid.first_name} ${kid.last_name}`;
                    }
                    return selected;
                }}
                onChange={handleChange}
                name={name}
                {...props}
            >

                {kids.map((kid) => {
                    const labelId = `checkbox-list-label-${kid.id}`;
                    const primary = `${kid.first_name} ${kid.last_name}`;
                    const secondary = calculateAgeAtDate(kid.birth_date, race.date);
                    return (
                        <MenuItem
                            key={kid.id}
                            value={kid.id}>
                            <ListItemAvatar>
                                {kid.gender === 'female' &&
                                    <Avatar sx={{bgcolor: "female.main"}}>
                                        <Girl></Girl>
                                    </Avatar>
                                }
                                {kid.gender === 'male' &&
                                    <Avatar sx={{bgcolor: "male.main"}}>
                                        <Boy></Boy>
                                    </Avatar>
                                }
                            </ListItemAvatar>
                            <ListItemText primary={primary} secondary={secondary}/>
                        </MenuItem>
                    );
                })}
                <MenuItem value={-1} onClick={handleOpen}>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: "primary.main"}}>
                            <Add/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Brakuje kogoś na liście?"} secondary="Dodaj profil dziecka"/>

                </MenuItem>
            </Select>
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            <KidCreateDialog open={open} onAdd={handleAdded} handleClose={handleClose}></KidCreateDialog>
        </FormControl>
    )
        ;
}