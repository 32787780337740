
import axios from "axios";
import React from "react";


// axios.interceptors.response.use(
//
//     (response) => {
//         return response
//     },
//     (err) => {
//         const event = new CustomEvent('axios:error', { detail: {err: err} });
//         window.dispatchEvent(event)
//
//         const status=err.status;
//
//         return Promise.reject(err)
//     }
// )



class AppClient {
    constructor() {
        this.controllers = {};
    }

    races = (event_id) => {
        return axios.get( '/api/races?event_id=' + event_id);
    }
    series = (race_id) => {
        return axios.get( '/api/races/' + race_id + '/series');
    }
    clauses = (race_id) => {
        return axios.get( '/api/races/' + race_id + '/clauses');
    }
    race = (race_id) => {
        return axios.get( '/api/races/' + race_id);
    }
    raceGalleries = (race_id) => {
        return axios.get( '/api/races/' + race_id + '/galleries');
    }
    raceGalleryPhotos = (race_id, gallery_id) => {
        return axios.get( '/api/races/' + race_id + '/galleries/' + gallery_id);
    }
    teams = (q) => {
        return axios.get('/api/teams', {params: {q: q}});
    }
    cart = () => {
        return axios.get( '/api/cart');
    }
    updateCart = (cartId, data) => {
        return axios.patch( '/api/cart/' + cartId, data);
    }
    checkout = (cartId, data) => {
        return axios.post( '/api/checkout/' + cartId, data);
    }
    createCartItem = (data) => {
        return this.cart().then((response) => {
            const cartId = response.data["id"];
            return axios.post( '/api/cart/' + cartId + '/items', {
                ...data
            });
        }).catch((error) => {
            const event = new CustomEvent('axios:error', { detail: {err: error} });
            window.dispatchEvent(event);
            return Promise.reject(error)
        })
    }
    patchCartItem = (cartId, itemId, data) => {
        return axios.patch( '/api/cart/' + cartId + '/items/' + itemId, data);
    }
    removeCartItem = (cartId, itemId) => {
        return axios.delete( '/api/cart/' + cartId + '/items/' + itemId);
    }
    createDiscountCode = (cartId, discountCode) => {
        return axios.post( '/api/cart/' + cartId + '/discount-codes', {
            "discountCode": discountCode
        });
    }
    deleteDiscountCode = (cartId, discountCodeId) => {
        return axios.delete( '/api/cart/' + cartId + '/discount-codes/' + discountCodeId);
    }
    insuranceEventQuotes = (eventId, seriesId, kidId, withoutShirt) => {
        const params = {
            'event_id' : eventId,
            'series_id': seriesId,
            'kid_id': kidId,
            'without_shirt': withoutShirt
        }
        return axios.get( '/api/insurance-event-quotes', {params: params});
    }
    insuranceTrainingQuotes = (kidId) => {
        const params = {
            'kid_id': kidId,
        }
        return axios.get( '/api/insurance-training-quotes', {params: params});
    }
    insuranceStartQuotes = (startId) => {
        const params = {
            'start_id': startId,
        }
        return axios.get( '/api/insurance-start-quotes', {params: params});
    }
    user = () => {
        const params = {}
        return axios.get( '/api/me', {params: params});
    }
    userStarts = () => {
        const params = {
            'date': 'upcoming',
        }
        return axios.get( '/api/me/starts', {params: params});
    }
    userOrder = (id) => {
        return axios.get( `/api/me/orders/${id}`);
    }
    userPayment = (id) => {
        return axios.get( `/api/me/payments/${id}`);
    }
    userKids = (id) => {
        return axios.get( `/api/me/kids`);
    }
    userKidsCreate = (data) => {
        return axios.post( '/api/me/kids', data);
    }
    userCoupons = () => {
        return axios.get( `/api/me/coupons`);
    }
    userCanSignUp = (id) => {
        return axios.get( `/api/me/can-sign-up/${id}`);
    }
    events = () => {
        return axios.get( '/api/events');
    }
    shippings = () => {
        return axios.get( '/api/shippings');
    }
    payments = (amount, currency) => {
        const params = {
            total: amount,
            currency: currency
        }
        return axios.get( '/api/payments', {params: params});
    }
    storeProductGroup = (id) => {
        return axios.get( '/api/store-product-groups/' + id);
    }
    settings = () => {
        return axios.get( '/api/settings');
    }
    sports = () => {
        return axios.get( '/api/user/sports');
    }
    findOut = () => {
        return axios.get( '/api/user/find-out');
    }
    uniqueEmail = (email, controllerKey = "default") => {
        if (this.controllers[controllerKey]) {
            this.controllers[controllerKey].abort();
        }
        this.controllers[controllerKey] = new AbortController();
        return axios.get( '/api/user/unique-email', {
            signal: this.controllers[controllerKey].signal,
            params: {email: email}
        });
    }
    register = (values) => {
        const {email, password, firstName, lastName, phoneNumber, processing, consent} = values;
        return axios.post( '/api/user/register', {
            email:email,
            password:password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            processing: processing,
            consent: consent,
        });
    }

    banners = () => {
        return axios.get( '/api/banners');
    }
}

export const client = new AppClient();