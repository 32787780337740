import {Box, Paper, styled} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PaymentChoice = ({payment, size = 128, selected=false, ...props}) => {
    const Wrapper = styled(Paper)(({theme}) => ({
        ...(props.onClick ? {
            '&:hover': {
                boxShadow: `0px 0px 5px ${theme.palette.primary.main}`,
                backgroundColor: `${theme.palette.primary.main}`
            },
            cursor: "pointer",
        } : {}),
        background: "white",
        display: "flex",
        alignItems: "center",
        p: 1,
        width: size,
        height: 0.6*size,
        position: "relative"
    }));


    return <Wrapper
        elevation={0}
        title={payment.name}
        {...props}
    >
        <img src={payment.logo} alt={payment.name}/>
        {selected && <CheckCircleIcon sx={{color: "black", "position":"absolute", "top": 4, right: 4}}/> }
    </Wrapper>
}

export default PaymentChoice