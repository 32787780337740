import React, {useRef, useState} from "react";
import {styled} from '@mui/material/styles';

import {Avatar, Box, CircularProgress, Dialog, DialogActions, Paper, Stack} from "@mui/material";
import {Close, Person, Save} from "@mui/icons-material";
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ChangeEvent} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import {logIn} from "../../../features/user/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {setIn} from "formik";
import {useTranslation} from "react-i18next";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const EditAvatar = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {authenticated, user} = useSelector((state) => state.user)
    const [inputKey, setInputKey] = useState(uuidv4());

    const size = 164;
    const [avatarUrl, setAvatarUrl] = useState();
    const cropperRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    // : ChangeEvent<HTMLInputElement>
    const getNewAvatarUrl = (e) => {
        if (e.target.files) {
            setAvatarUrl(URL.createObjectURL(e.target.files[0]));
        }
        setInputKey(uuidv4());
    };

    const getCropData = async () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setIsUploading(true);
            cropper.disable();
            const file = await fetch(cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9))
                .then((res) => res.blob())
                .then((blob) => {
                    const fileName = `${uuidv4()}.jpg`
                    return new File([blob], fileName, {type: "image/jpeg"});
                });
            if (file) {
                const formData = new FormData();
                formData.append(
                    "file",
                    file,
                    file.name
                );
                axios.post( '/api/me/avatar', formData).then(response => {
                    dispatch(logIn(response.data))
                    setIsUploading(false);
                    setAvatarUrl(null);
                    cropper.enable();
                }, error => {
                    setIsUploading(false);
                    cropper.enable();
                });
            }
        }
    };

    return <Paper sx={{width: size, padding: 2}}>
        <Stack direction="column" spacing={2} sx={{}}>
            <Avatar sx={{width: size, height: size}}>
                {!user.avatar && <Person sx={{fontSize: size}}/> }
                { user.avatar && <img src={user.avatar} style={{}} alt="" />}
            </Avatar>
            <Stack justifyContent={"center"}>
                <Button component="label" variant="contained" startIcon={<CloudUploadIcon/>}>
                    {t('Upload photo')}
                    <VisuallyHiddenInput
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        onChange={getNewAvatarUrl}
                        key={inputKey}
                    />
                </Button>
            </Stack>
        </Stack>
        <Dialog open={Boolean(avatarUrl)} fullWidth={true} maxWidth="md">
            <Cropper
                src={avatarUrl}
                style={{height: 400, width: "100%"}}
                viewMode={1}
                aspectRatio={1}
                minCropBoxHeight={64}
                minCropBoxWidth={64}
                guides={false}
                checkOrientation={false}
                center={true}
                autoCropArea={1}
                ref={cropperRef}
                zoomable={true}
                zoom={(ev) => console.log(ev)}
            />
            <DialogActions sx={{justifyContent: "space-between"}}>
                <Button
                    onClick={() => setAvatarUrl(null)}
                    endIcon={<Close/>}
                    variant="outlined"
                >{t('Close')}</Button>
                <Button
                    disabled={isUploading}
                    onClick={getCropData}
                    endIcon={isUploading ? <CircularProgress size="1em"/> : <Save/>}
                    variant="contained"
                >{t('Save')}</Button>

            </DialogActions>
        </Dialog>

    </Paper>

}


export default EditAvatar;