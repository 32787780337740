import {ThemeProvider} from "@mui/material";
import React, {createElement, Suspense, useEffect, useState} from "react";
import {render, unmountComponentAtNode} from "react-dom";
import LoginDialog from "../LoginDialog";
import ErrorDialog from "../Common/ErrorDialog";
import {theme} from "../../lib/theme";


const Errors = () => {
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        const handlerError = event => {
            const err = event.detail.err
            if (err.response.status === 403) {
                setOpen(true);
            } else if (err.response.status === 422) {
                if (err.response.data?.errors !== undefined) {
                    if (typeof err.response.data.errors == 'object') {
                        setErrors([err.response.data.errors]);

                    } else {
                        setErrors(err.response.data.errors.map(e => ("errors." + e)));

                    }
                }
            }

        };

        window.addEventListener('axios:error', handlerError);

        return () => {
            window.removeEventListener('axios:error', handlerError);
        };
    }, []);

    const handleErrorsClose = () => {
        setErrors([]);
    }
    const handleLoginClose = () => {
        setOpen(false);
    }


    return <>
        <LoginDialog open={open} handleClose={handleLoginClose}/>
        <ErrorDialog open={errors.length > 0} message={errors.join(" ")} handleClose={handleErrorsClose}/>
    </>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <Errors {...props}/>
        </Suspense>
    </ThemeProvider>
}


class ErrorsHTMLElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value]);
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-errors', ErrorsHTMLElement)

export default ErrorsHTMLElement