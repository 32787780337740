import { createSlice } from '@reduxjs/toolkit'
import {client} from "../../lib/Client";

export const upcomingEventSlice = createSlice({
    name: 'upcomingEvent',
    initialState: {
        loading: true,
        event: {}
    },
    reducers: {
        setEvent: (state, action) => {
            state.event = action.payload
            state.loading = false
        },
    },
});

// Action creators are generated for each case reducer function
export const { setEvent } = upcomingEventSlice.actions


export default upcomingEventSlice.reducer
