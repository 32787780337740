import * as React from 'react';
import {QRCodeSVG} from "qrcode.react";
import {formatRaceName} from "../../lib/utils";


const Coupon = ({coupon}) => {
    console.log(coupon);
    return (
        <div className="closest-event grid-item radius-box grid-item-coupon">
            <div className="grid-item-company">
                {coupon.coupon_type.image &&
                    <img src={coupon.coupon_type.image} alt={coupon.coupon_type.name}
                         className="coupons-company-image"/>
                }
                {coupon.coupon_type.show_qr_code &&
                    <div className="coupons-company-qr">
                        <QRCodeSVG
                            id={`QRCode-${coupon.code}`}
                            value={coupon.code}
                            size={164}
                            includeMargin={true}
                            level={'H'}
                            style={{maxWidth: "100%"}}
                        />
                    </div>
                }
            </div>
            <div className="grid-item-content">
                {coupon.start && <div className="coupons-company-desc">
                    <div>
                        <b>Start:</b> { formatRaceName(coupon.start.series.event) }
                    </div>
                </div> }
                <div className="coupons-company-desc"
                     dangerouslySetInnerHTML={{__html: coupon.coupon_type.content}}></div>
                <div className="coupons-company-code">
                    <div className="coupons-company-code-desc coupons-company-code-style">
                        Twój kod:
                    </div>
                    <div className="coupons-company-code-code coupons-company-code-style">
                        {coupon.code}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Coupon;