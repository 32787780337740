import Cookies from "universal-cookie";

function prepareFrame(tburl) {
    const ifrm = document.createElement("IFRAME");
    ifrm.setAttribute("src", tburl);
    ifrm.style.width = 1 + "px";
    ifrm.style.height = 1 + "px";
    ifrm.style.border = "none";
    document.body.appendChild(ifrm);
}

function prepareImage(tburl) {
    const ifrm = document.createElement("IFRAME");
    ifrm.setAttribute("src", tburl);
    ifrm.setAttribute("width", 1)
    ifrm.setAttribute("height", 1)
    ifrm.style.display = "none"
}

const googleTagCommerceOrder = (order) => {
    const coupons = []
    for (const discountCode of order.discount_codes) {
        coupons.push(discountCode.code);
    }
    const coupon = coupons.join(" ");
    const tag = {
        transaction_id: order.id,
        value: parseInt(order.total.amount) / 100,
        currency: order.total.currency.code,
        coupon: coupon,
        items: []
    }
    for (let i = 0; i < order.items.length; i++) {
        const item = order.items[i];
        let itemId = item.name
        let category = item.name
        let variant = item.name
        let isElite = 0;
        if ("series" in item) {
            itemId = item.series.event.slug;
            category = item.series.event.place;
            variant = `${item.series.event.date} ${item.series.event.place}`
            if (item.series.elite_classification) {
                isElite = 1
            }
        }
        const tagItem = {
            item_id: itemId,
            item_name: item.name,
            affiliation: 'Google Merchandise Store',
            coupon: coupon,
            currency: order.total.currency.code,
            index: i,
            item_brand: "Runmageddon",
            item_category: category,
            item_list_id: 'related_products',
            item_list_name: 'Related products',
            item_variant: variant,
            price: parseInt(item.total.amount) / 100,
            quantity: item.quantity,
            is_elite: isElite
        }
        tag.items.push(tagItem);
    }
    console.log(tag);
    return tag
}

export const trackCheckout = (cart, user) => {
    const cookies = new Cookies();

    const gtm_products = [];
    for (const item of cart.items) {
        gtm_products.push({
            'name': item.name,
            'category': 'biegi',
            'price': parseInt(item.total.amount) / 100,
            'quantity': 1
        });
    }
    dataLayer.push({
        'event': 'trackTrans',
        'transactionId': '<?php echo $this->payment->getId(); ?>',
        'transactionAffiliation': 'runmageddon',
        'transactionTotal': parseInt(cart.total.amount) / 100,
        'transactionTax': 0,
        'transactionShipping': 0,
        'transactionCurrency': parseInt(cart.total.currency.code),
        'transactionProducts': gtm_products,
        'userEmail': user.email,
        'userPhone': user.phone_number,
    })
    ;
    if (typeof fbq == "function") {
        fbq('track', 'Purchase', {
            currency: cart.total.currency.code,
            value: parseInt(cart.total.amount) / 100
        });
    }

    gtag('event', 'begin_checkout', googleTagCommerceOrder(cart));
    gtag('event', 'conversion', {'send_to': 'AW-967864710/_Ir4CIrf7pUBEIbjwc0D'});
    //trade doubler

    const tduid = cookies.get("_tduid")
    if (tduid) {
        const organization = 2197703;
        const eventPayment = 387669;
        const eventOrder = 386073;
        const orderId = cart.id;
        const amount = parseInt(cart.total.amount) / 100;
        const currency = cart.total.currency.code;

        // track payment
        prepareFrame(`https://tbs.tradedoubler.com/report?organization=${organization}&event=${eventPayment}&orderValue=${amount}&orderNumber=${orderId}&currency=${currency}&tduid=${tduid}&type=iframe`)
        prepareImage(`https://imgstatic.eu/report?o=${organization}&e=${eventPayment}&ordnum=${orderId}&ordval=${amount}&curr=${currency}&tduid=${tduid}`);
        // track order
        prepareFrame(`https://tbl.tradedoubler.com/report?organization=${organization}&event=${eventOrder}&leadNumber=${orderId}&tduid=${tduid}&type=iframe`);
        prepareImage(`https://imgstatic.eu/report?o=${organization}&e=${eventOrder}&leadnum=${orderId}&curr=${currency}&tduid=${tduid}`)
    }
}

export const trackThankYou = (payment, order) => {
    if (payment) {
        if (typeof fbq == "function") {
            fbq('track', 'Purchase', {
                currency: payment.total.currency.code,
                value: parseInt(payment.total.amount) / 100
            });
        }

        gtag('event', 'conversion', {
            'send_to': 'AW-967864710/CVo3CPuq_YMBEIbjwc0D',
            'value': parseInt(payment.total.amount) / 100,
            'currency': payment.total.currency.code
        });
        if (order) {
            gtag('event', 'purchase', googleTagCommerceOrder(order));
        }
    }
}
