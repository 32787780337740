import {AddToCart} from "../AddToCart";
import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense} from "react";
import {ThemeProvider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {theme} from "../../../lib/theme";


export const AddToCartStarterPackShirt = ({start_id}) => {
    const {t} = useTranslation();

    return <AddToCart size="small" variant="outlined" label="Dokup koszulkę" data={[{type: "starter_pack_shirt", start_id: start_id}]}/>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <AddToCartStarterPackShirt {...props}/>
        </Suspense>
    </ThemeProvider>
}

class AddToCartStarterPackShirtElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-add-to-cart-starter-pack-shirt', AddToCartStarterPackShirtElement)

export default AddToCartStarterPackShirtElement