import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense, useEffect, useState} from "react";
import {
    Box,
    Button, CircularProgress,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider
} from "@mui/material";
import {formatMoney, theme} from "../../../lib/utils";
import {useTranslation} from "react-i18next";
import {client} from "../../../lib/Client";
import {AddToCartWrap} from "../../Order/AddToCartWrap";


export const Product = ({product}) => {
    const {t} = useTranslation();

    const url = "sklep/" + product.slug;
    // todo refactor to cart
    const price = product.prices.filter(price => price.currency.code === "PLN")[0]

    return <Box className="store-product">
        <div className="store-product-image">
            {product.images.length > 0 && <a href={url}><img src={product.images[0].file} /></a>}
        </div>
        <div className="store-product-information">
            <div className="store-product-information-name">
                <a href={url}>{product.name}</a>
            </div>
            <div className="store-product-information-price">
                {formatMoney(price)}
            </div>
        </div>
        <div className="store-product-cart">
            <AddToCartWrap product={product}/>
        </div>
    </Box>
}
