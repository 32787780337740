import {
    Button,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import nl2br from "react-nl2br";


const Know = () => {
    const {t} = useTranslation();


    return <>
        <Typography sx={{mb:3}} variant="h5" align="center">{nl2br(t('user.register.welcomeHeading'))}</Typography>
        <Typography sx={{mb: 2, textAlign:"center"}}>
            {nl2br(t('user.register.welcomeContent'))}
        </Typography>
        <Button variant="contained" fullWidth href={t('routes.login')} sx={{mt: 3}}>
            {t('user.login.login')}
        </Button>
    </>
}

export default Know;