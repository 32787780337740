import React, {useEffect, useState} from 'react';
import {
    Box, Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress, Dialog, DialogContent, DialogTitle, FormControl,
    FormControlLabel, IconButton, InputLabel, MenuItem, Select, Typography
} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import InvoiceDialog from "../InvoiceDialog";
import {useTranslation} from "react-i18next";
import {client} from "../../../lib/Client";
import PhoneIcon from "@mui/icons-material/Phone";
import ShippingDialog from "../ShippingDialog";
import CloseIcon from "@mui/icons-material/Close";

const PickupInPersonShipping = ({cart, updateCart}) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {

        client.events().then(response => {
            setEvents(response.data);
        }, error => {
            console.log(error);
        });
    }, []);

    const eventName = (event) => {
        return event.name + " " + event.place + " " + event.date;
    }
    if (events.length == 0) {
        return <></>
    }
    const event = events.filter((item) => item.id === parseInt(cart.pickup_point_data.id)).pop()
    return <>{event ? eventName(event) : ''}</>
}
const CourierShipping = ({cart}) => {
    const {t} = useTranslation();

    return <>
        <Box sx={{mb: 1, mt: 1}}>
            {cart.shipping_data.company} {cart.shipping_data.company ? <br/> : ""}
            {cart.shipping_data.first_name} {cart.shipping_data.last_name}<br/>
            {cart.shipping_data.street} {cart.shipping_data.house_number} {cart.shipping_data.flat_number ? " / " + cart.shipping_data.flat_number : ""}<br/>
            {cart.shipping_data.zip_code} {cart.shipping_data.town}<br/>
            <PhoneIcon fontSize="small" sx={{verticalAlign: "middle"}}/> {cart.shipping_data.phone_number}<br/>
        </Box>
    </>
}
const InPostParcelLockerShipping = ({cart}) => {
    if (cart.pickup_point_data.id == null) {
        return <></>
    }
    return <><br/><b>{cart.pickup_point_data.name}</b><br/>
        {cart.pickup_point_data.address}<br/>
        {cart.pickup_point_data.zip_code} {cart.pickup_point_data.town}<br/>
    </>
}
const NoneShipping = () => {
    return <></>
}

const components = {
    "PickupInPersonShipping": PickupInPersonShipping,
    "CourierShipping": CourierShipping,
    "InPostParcelLockerShipping": InPostParcelLockerShipping,
    "": NoneShipping,
};

const CheckoutShipping = ({cart}) => {
    const {t} = useTranslation();

    if (!cart.shipping_required) {
        return <></>
    }

    const ShippingComponent = cart.shipping ? components[cart.shipping.discriminator] : components[""]


    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.chosenShipping')}
                </Typography>
                <Box sx={{mt: 1, mb: 1}}>
                    <div><b>{cart.shipping.name}</b></div>
                    <ShippingComponent cart={cart}/>
                </Box>
            </CardContent>
        </Card>
    </React.Fragment>
}

export default CheckoutShipping