import { createSlice } from '@reduxjs/toolkit'
import {client} from "../../lib/Client";

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        loading: true,
        settings: {}
    },
    reducers: {
        setValues: (state, action) => {
            state.settings = action.payload
            state.loading = false
        },
    },
});

// Action creators are generated for each case reducer function
export const { setValues } = settingsSlice.actions


export default settingsSlice.reducer
