import React, {useEffect, useState} from 'react';
import {formatMoney} from "../../../lib/utils";
import {
    Button,
    Card,
    Radio,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Typography,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    DialogActions, Dialog, IconButton
} from "@mui/material";
import {useTranslation, Trans} from 'react-i18next';
import {client} from "../../../lib/Client";
import InvoiceDialog from "../InvoiceDialog";
import ShippingDialog from "../ShippingDialog";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from '@mui/icons-material/Phone';

const PickupInPersonShipping = ({cart, updateCart}) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {

        client.events().then(response => {
            const events = response.data.filter((ev) => ev.id === 122)
            setEvents(events);
        }, error => {
            console.log(error);
        });
    }, []);

    const handleChange = (event) => {
        // setLoading(true);

        const data = {
            pickup_point: event.target.value
        }

        updateCart(data).then(() => {
            // setLoading(false);
            // handleClose();
        }).catch((error) => {
            // setLoading(false);
            // if (error.response) {
            //     const error_messages = {};
            //     Object.keys(error.response.data["errors"]).forEach((key, index) => {
            //         error_messages[key] = Object.values(error.response.data["errors"][key]).join(". ")
            //     });
            //     setErrors(error_messages);
            // }
        })
    }

    const eventName = (event) => {
        return event.name + " " + event.place + " " + event.date;
    }
    return <FormControl fullWidth variant="standard">
        <InputLabel id="select-event-label">Wybierz event</InputLabel>
        {events.length > 0 &&
        <Select
            labelId="select-event-label"
            value={cart.pickup_point_data.id === null ? "" : cart.pickup_point_data.id}
            label="Wybierz"
            onChange={handleChange}
        >
            {events.map((event => <MenuItem
                value={event.id}
                key={event.id}>{eventName(event)}</MenuItem>))}
        </Select>}
    </FormControl>
}
const CourierShipping = ({cart, updateCart}) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true)
    }

    return <>
        <b>{t('shipping.shippingAddress')}</b>
        <Box sx={{mb: 1, mt: 1}}>
            {cart.shipping_data.company} {cart.shipping_data.company ? <br/> : ""}
            {cart.shipping_data.first_name} {cart.shipping_data.last_name}<br/>
            {cart.shipping_data.street} {cart.shipping_data.house_number} {cart.shipping_data.flat_number ? " / " + cart.shipping_data.flat_number : ""}<br/>
            {cart.shipping_data.zip_code} {cart.shipping_data.town}<br/>
            <PhoneIcon fontSize="small" sx={{verticalAlign: "middle"}}/> {cart.shipping_data.phone_number}<br/>
        </Box>
        <Button variant="outlined" size="small" onClick={handleClick}>Zmień</Button>
        <ShippingDialog cart={cart} open={open} handleClose={handleClose} updateCart={updateCart}/>
    </>
}
const InPostParcelLockerShipping = ({cart, updateCart}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const toggle = (open) => () => {
        setOpen(open);
    }
    const handleSelect = (event) => {
        // setLoading(true);
        const data = {
            pickup_point: event.detail
        }
        updateCart(data).then(() => {
            // setLoading(false);
            setOpen(false);
        }).catch((error) => {
            // setLoading(false);
        })
    }
    React.useEffect(() => {
        document.addEventListener('onpointselect', handleSelect);
        // cleanup this component
        return () => {
            document.removeEventListener('onpointselect', handleSelect);
        };
    }, []);

    const chosenPoint = (cart) => {
        if (cart.pickup_point_data.id == null) {
            return <></>
        }
        return <><br/><b>{cart.pickup_point_data.name}</b><br/>
            {cart.pickup_point_data.address}<br/>
            {cart.pickup_point_data.zip_code} {cart.pickup_point_data.town}<br/>
        </>
    }

    return <>
        <Button variant="outlined" size="small" onClick={toggle(true)}>Wybierz paczkomat</Button>
        {chosenPoint(cart)}
        <Dialog open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle>
                {t('Wybierz paczkomat')}
                <IconButton
                    aria-label="close"
                    onClick={toggle(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div style={{height: 480}}>
                    <inpost-geowidget onpoint="onpointselect" token={cart.shipping.geowidget_api_key} language='pl'
                                      config='parcelcollect'></inpost-geowidget>
                </div>
            </DialogContent>
        </Dialog>
    </>
}
const NoneShipping = () => {
    return <></>
}

const components = {
    "PickupInPersonShipping": PickupInPersonShipping,
    "CourierShipping": CourierShipping,
    "InPostParcelLockerShipping": InPostParcelLockerShipping,
    "": NoneShipping,
};

const CartShipping = ({cart, updateCart}) => {
    const {t} = useTranslation();
    const [shippings, setShippings] = useState([]);
    const [loading, setLoading] = useState(false);

    const currency = cart.total.currency


    const ShippingComponent = cart.shipping ? components[cart.shipping.discriminator] : components[""]

    const fetchShippings = () => {
        setLoading(true);
        return client.shippings().then((response) => {
            setShippings(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching shippings', error)
        });
    }

    const handleChange = (ev) => {
        setLoading(true)
        updateCart({shipping: ev.target.value}).then(() => {
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchShippings();
    }, []);

    if (!cart.shipping_required) {
        return <></>
    }

    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.chooseShipping')} {loading && <CircularProgress size="1em"/>}
                </Typography>

                <FormControl fullWidth>
                    <RadioGroup
                        value={cart.shipping ? cart.shipping.id : ""}
                        name="radio-buttons-group"
                        onChange={handleChange}
                    >
                        {shippings.map((item, index) => {
                            const ShippingComponent = components[item.discriminator];
                            const price = item.prices.filter(price => price.currency.code === currency.code)[0]
                            const label = <>
                                {item.name} <b>{formatMoney(price)}</b>
                            </>
                            return <div key={item.id}>
                                <FormControlLabel value={item.id} control={<Radio/>} label={label}/>
                            </div>
                        })}
                    </RadioGroup>
                </FormControl>
                <ShippingComponent cart={cart} updateCart={updateCart}/>
            </CardContent>
        </Card>
    </React.Fragment>
}


export default CartShipping

