import React from "react";
import {formatMoney} from "../../../../lib/utils";
import {
    Avatar,
    Checkbox,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText, Typography
} from "@mui/material";

const InsuranceItem = ({item, checked, toggle}) => {
    const value = item.id
    const labelId = `checkbox-list-label-${value}`;
    const price = formatMoney({amount: item.price*100, currency: {code: "PLN"}});
    const primary = <>{item.name} <Typography variant="span" sx={{color: 'primary.main', fontWeight: "bold", float: 'right'}} >{price}</Typography></>

    const handleClick = (ev) => {
        toggle(value);
    }

    return <ListItem
        disablePadding
    >
        <ListItemButton role={undefined} dense onClick={handleClick}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                />


            </ListItemIcon>
            <ListItemText id={labelId} primary={primary} secondary={item.description} />
        </ListItemButton>
    </ListItem>

    // function handleChange() {
    //     props.toggle(props.id);
    // }
    // return <div className="insurance-item">
    //     {!props.showName &&
    //     <label htmlFor={"insurance_" + props.id}>
    //         <p><small className="insurance-description">{props.description}</small></p>
    //     </label> }
    //     <div className={"form-check"}>
    //         <input type={"checkbox"} name={props.input_name + "[]"} value={props.id} id={"insurance_" + props.id} checked={props.chosen.includes(props.id)} onChange={handleChange}/>
    //         <label htmlFor={"insurance_" + props.id}>
    //             <strong>
    //                 {props.showName && <span className={"insurance-name"}>{props.name}</span>} <span>{formatMoney({amount: props.price*100, currency: {code: "PLN"}})}</span>
    //             </strong>
    //         </label>
    //     </div>
    //     {props.showName &&
    //     <label htmlFor={"insurance_" + props.id}>
    //         <p><small className="insurance-description">{props.description}</small></p>
    //     </label> }
    // </div>
}

export default InsuranceItem;