import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import {ShoppingCart} from "@mui/icons-material";
import CartItem from "../CartItem";
import {get} from "../../../lib/utils";


const AddToCartDialog = ({open, items, handleClose}) => {

    const {t} = useTranslation();
    const [errors, setErrors] = useState({});

    const handleGoToCart = () => {
        get(t('routes.cart'))
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth="md">
            <DialogTitle>
                {t('cart.itemAdded')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {items.map((item) => <CartItem item={item} key={item.id}/>)}
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="contained" onClick={handleGoToCart} endIcon={<ShoppingCart/>}>
                    {t('cart.goToCart')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddToCartDialog