import Alerts from "./features/alerts/Alerts";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {useEffect} from "react";
import Login from "./routes/user/login";
import {Cart} from "./components/Order/Cart";
import EventGallery from "./routes/EventGallery";
import EventGalleryPhoto from "./routes/EventGallery/photo";
import Default from "./routes/default";
import {useDispatch} from "react-redux";
import {client} from "./lib/Client";
import {setValues} from "./features/settings/settingsSlice";
import Index from "./routes/Index";
import Register from "./routes/user/register";
import SignUp from "./routes/SignUp";
import {setCart} from "./features/cart/cartSlice";
import {refreshCart} from "./lib/utils";
import Checkout from "./routes/Checkout";
import { ScrollRestoration } from "react-router-dom";
import ThankYou from "./routes/ThankYou";
import {useCookies} from "react-cookie";
import {logIn} from "./features/user/userSlice";
import QrCodeDialog from "./components/Common/QrCodeDialog";
import { ToastContainer, toast } from 'react-toastify';
import User from "./routes/user";
import 'react-clock/dist/Clock.css';
import 'react-toastify/dist/ReactToastify.css';
import UserCoupons from "./routes/user/UserCoupons";

const SESSION_COOKIE_NAME = 'app';

const router = createBrowserRouter([
    {
        path: "/uzytkownik/login",
        element: <Login/>,
        default: true
    },
    {
        path: "/user/login",
        element: <Login/>,
        default: false
    },
    {
        path: "/user/register",
        element: <Register/>,
        default: true
    },
    {
        path: "/uzytkownik/register",
        element: <Register/>,
        default: false
    },
    {
        path: "/cart",
        element: <Cart/>,
        default: false
    },
    {
        path: "/koszyk",
        element: <Cart/>,
        default: false
    },
    {
        path: "/checkout",
        element: <Checkout/>,
        default: false
    },
    {
        path: "/zamawiam",
        element: <Checkout/>,
        default: false
    },
    {
        path: "/galeria/:eventSlug/:gallerySlug/",
        element: <EventGallery/>,
        children: [
            {
                path: ":photoId",
                element: <EventGalleryPhoto/>,
            },
        ],
    },
    {
        path: "/gallery/:eventSlug/:gallerySlug/",
        element: <EventGallery/>,
        children: [
            {
                path: ":photoId",
                element: <EventGalleryPhoto/>,
            },
        ],
    },
    {
        path: "/zapisy/:raceSlug",
        element: <SignUp/>,

    },
    {
        path: "/thank-you",
        element: <ThankYou/>,

    },
    {
        path: "/uzytkownik/recover",
        element: <Default/>,
    },
    {
        path: "/uzytkownik",
        element: <User/>,
        children: [
            {
                path: "znizki",
                element: <UserCoupons/>,
            },
            {
                path: "*",
                element: <Default/>,
                default: true
            },
        ],
    },
    {
        path: "/",
        element: <Index/>,
        default: true
    },
    {
        path: "*",
        element: <Default/>,
        default: true
    }
]);

export default function App() {
    const [cookies, setCookie, removeCookie] = useCookies([SESSION_COOKIE_NAME]);
    const dispatch = useDispatch()
    useEffect(() => {
        client.settings().then((response) => {
            dispatch(setValues(response.data))
        });
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(top.location.search);
        const ref = params.get("r");
        if (ref) {
            localStorage.setItem('ref', ref);
            localStorage.removeItem('ref_shown');
        }
        refreshCart();
    }, []);

    useEffect(() => {
        if (SESSION_COOKIE_NAME in cookies) {
            client.user().then((response) => {
                dispatch(logIn(response.data));
            }).catch((error) => {

            })
        }
    }, [cookies])

    return <>
        <ToastContainer
            // position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />

        <Alerts/>
        <QrCodeDialog/>
        <RouterProvider router={router} />
    </>
}