import {useDispatch, useSelector} from "react-redux";
import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import QRCode from "react-qr-code";
import {QRCodeSVG} from 'qrcode.react';
import {closeQrDialog} from "../../../features/user/userSlice";
import DownloadIcon from '@mui/icons-material/Download';

const QrCodeDialog = () => {
    const dispatch = useDispatch()
    const {authenticated, user, qrCodeDialog} = useSelector((state) => state.user)
    const {t} = useTranslation();
    const open = qrCodeDialog;

    const size = 320;

    const handleClose = () => {
        dispatch(closeQrDialog());
    }

    const onImageDownload = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = t('qrCodeDialog.title');
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.width = size;
        img.height = size;
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    if (!authenticated) {
        return <></>
    }
    const value = user.id.toString();

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="qrcode-dialog-title">
                {t('qrCodeDialog.title')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{textAlign: "center", mb: 2}}>
                    {t('qrCodeDialog.description')}
                </DialogContentText>
                <DialogContentText sx={{textAlign: "center"}}>
                    <QRCodeSVG
                        id="QRCode"
                        value={value}
                        size={size}
                        includeMargin={true}
                        level={'H'}
                        style={{maxWidth: "100%"}}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onImageDownload} endIcon={<DownloadIcon/>} variant="contained">
                    {t('qrCodeDialog.download')}
                </Button>
                <Button onClick={handleClose} endIcon={<CloseIcon/>} variant="outlined">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default QrCodeDialog;