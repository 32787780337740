import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        authenticated: false,
        user: {},
        qrCodeDialog: false
    },
    reducers: {
        logIn: (state, action) => {
            state.user = action.payload
            state.loading = false
            state.authenticated = true
        },
        setLoading: (state, action) => {
            state.loading = true
        },
        logOut: (state, action) => {
            state.user = {}
            state.loading = false
            state.authenticated = false
        },
        openQrDialog: (state) => {
            state.qrCodeDialog = true;
        },
        closeQrDialog: (state) => {
            state.qrCodeDialog = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { logIn, setLoading, logOut, openQrDialog, closeQrDialog } = userSlice.actions

export default userSlice.reducer
