import LoginForm from "../../components/Forms/LoginForm";
import {Card, CardContent, Container, Tabs, Tab, Typography, Box, Alert} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation} from "react-router-dom";


import QrCode2Icon from '@mui/icons-material/QrCode2';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyIcon from '@mui/icons-material/Key';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SecurityIcon from '@mui/icons-material/Security';
import {useWidth} from "../../lib/hooks";
import {navigation} from "../../lib/user";

const User = () => {
    const {t} = useTranslation();
    const [value, setValue] = React.useState(0);
    const [subValue, setSubValue] = React.useState(0);
    const location = useLocation();
    const width = useWidth();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = navigation;

    React.useEffect(() => {
        const path = location.pathname;

        for (const [i, tab] of tabs.entries()) {
            if ("path" in tab && t(tab.path) === path) {
                setValue(i);
                return;
            }
            if (tab.children) {
                for (const [j, child] of tab.children.entries()) {
                    if ("path" in child && t(child.path) === path) {
                        setValue(i);
                        setSubValue(j);
                        return;
                    }
                }
            }
        }
    }, [location]);

    const tab = tabs[value];

    const isMobile = ["xs", "s", "sm"].includes(width)

    return <>
        <Box sx={{mb: 2}}>
            <Tabs
                value={value}
                // onChange={handleChange}
                sx={{borderBottom: 1, borderColor: 'divider'}}
                variant={!isMobile ? "fullWidth" : "scrollable"}
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                {navigation.map((item, index) => {
                    const props = {}
                    if ("path" in item) {
                        props["href"] = t(item.path);
                        props["component"] = "a"
                    } else if ("onClick" in item) {
                        props["onClick"] = item.onClick;
                    }
                    const IconType = item.icon.type;
                    const Icon = IconType === 'i' ? <IconType {...item.icon.props} style={{fontSize: "28px"}}/> :
                        <IconType {...item.icon.props} fontSize="large"/>
                    return <Tab
                        key={index}
                        icon={Icon}
                        label={!isMobile ? t(item.label) : null}
                        {...props}
                    />

                })}

            </Tabs>
            <Tabs
                variant="scrollable"
                // sx={{minHeight: "75px"}}
                value={subValue}
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                {tab.children && tab.children.map((item, index) => {
                    const props = {}
                    if ("path" in item) {
                        props["href"] = t(item.path);
                        props["component"] = "a"
                    } else if ("onClick" in item) {
                        props["onClick"] = item.onClick;
                    }
                    return <Tab
                        key={index}
                        icon={!isMobile ? item.icon : null}
                        label={t(item.label)}
                        iconPosition="start"
                        {...props}
                    />
                })}
            </Tabs>
        </Box>
        <Box sx={{mb: 2}}>
            <Alert severity="info" color="warning">
                PAMIĘTAJ BY ZREALIZOWAĆ KOD* O WARTOŚCI 100 ZŁ, KTÓRY POJAWI SIĘ ZARAZ PO ZAKUPIE STARTU, NA TWOIM KONCIE UŻYTKOWNIKA, W ZAKŁADCE TWOJE ZNIŻKI! BY GO WYKORZYSTAĆ ODWIEDŹ <a href="//sklep.runmageddon.pl" target="_blank" rel="noreferrer">SKLEP.RUNMAGEDDON.PL</a>
                <br/><br/>
                * nie dotyczy formuł KIDS i GAMES
            </Alert>
        </Box>
        <Outlet/>
    </>
}

export default User;