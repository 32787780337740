import React from "react";
import InsuranceItem from "../InsuranceItem";

function InsuranceProduct({input_name, name, items, chosen, toggle}) {

    return <div className="insurance-product">
        <div className="insurance-product-name">{name}</div>
        {input_name === "insurance_training" &&  <div className="insurance-product-name-sub">12 miesięcy</div> }
        {items.map((item => <InsuranceItem key={item.id} {...item} input_name={input_name} showName={items.length > 1}
                                           chosen={chosen} toggle={toggle}/>))}
    </div>
}

export default InsuranceProduct;