import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, IconButton,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from '@mui/icons-material/Close';
import {post} from "../../lib/utils";
import LoginForm from "../Forms/LoginForm";


const LoginDialog = ({open, handleClose}) => {
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {t('user.login.login')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <LoginForm/>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

export default LoginDialog