import {createTheme, responsiveFontSizes} from "@mui/material";
import {blue, pink} from "@mui/material/colors";

let theme = createTheme({
    palette: {
        primary: {
            main: "#ffde00"
        },
        secondary: {
            main: "#ffffff",
        },
        mode: 'dark',
        male: {
            main: blue[500]
        },
        female: {
            main: pink[500]
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            s: 480,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    }
});
theme = createTheme(theme, {
    typography: {
        fontFamily: 'proxima-nova, Open Sans',
    },

    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                },

            },
        },
        //     MuiTextField: {
        //         styleOverrides: {
        //             label: {
        //
        //             }
        //         }
        //     },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    //position: 'relative',
                },
                shrink: {
                    transform: 'translate(10px, 0px) scale(0.75) !important',
                },
                outlined: {
                    transform: 'translate(10px, 0px) scale(0.75)',
                },
            }
        },
        MuiOutlinedInput: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        input: {
                            padding: '5px 6px',
                        }
                    },
                },
            ],
            styleOverrides: {
                root: {
                    'label + &': {
                        marginTop: '22px', //defaultTheme.spacing(2),
                    }
                },
                input: {
                    padding: '10px 12px',
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.default} inset`,
                        WebkitTextFillColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        border: 'none',
                        borderRadius: 0
                    },
                },
                notchedOutline: {
                    top: 0,
                    '& > legend': {
                        display: 'none'
                    }
                }
            }
        },
        MuiList: {
            variants: [
                {
                    props: { variant: 'grid' },
                    style: {
                        width: '100%',
                        [theme.breakpoints.up('md')]: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4,1fr)'
                        },
                    },
                }
            ]
        },
        MuiFormControlLabel: {
            styleOverrides: {
                asterisk: {
                    color: theme.palette.primary.main,
                    fontSize: "1.5em"
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: {variant: 'small'},
                    style: {
                        fontSize: "0.75rem",
                    }
                }
            ]
        }
    }
});
theme = responsiveFontSizes(theme);
export {theme};