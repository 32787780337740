import {Button, ButtonGroup, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Done, NotInterested} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";

const YesNoToggle = ({value, onChange}) => {
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        if (onChange) {
            onChange(event, newValue);
        }
    }

    const handleClick = (newValue) => (event) => {
        if (onChange) {
            onChange(event, newValue);
        }
    }

    return (
        <React.Fragment>

            <ButtonGroup
                disableElevation
                size="large"
            >
                <Button
                    fullWidth={true}
                    color="error"
                    onClick={handleClick(false)}
                    variant={value === false ? "contained" : "outlined"}
                    startIcon={<NotInterested/>}
                >{t('no')}</Button>
                <Button
                    fullWidth={true}
                    color="success"
                    onClick={handleClick(true)}
                    variant={value === true ? "contained" : "outlined"}
                    endIcon={<Done/>}
                >{t('yes')}</Button>
            </ButtonGroup>

        </React.Fragment>
    );
}
export default YesNoToggle;
/*
<ToggleButtonGroup
                value={value}
                exclusive
                onChange={handleChange}
                aria-label="text alignment"
                color="primary"
                sx={{width: "100%"}}
            >
                <ToggleButton value={false} aria-label="left aligned" color="error">
                    <NotInterested/> {t('no')}
                </ToggleButton>
                <ToggleButton value={true} aria-label="centered" color="success">
                    {t('yes')} <Done/>
                </ToggleButton>

            </ToggleButtonGroup>
 */