import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense, useEffect, useState} from "react";
import {
    Box,
    CircularProgress,
    ThemeProvider, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {client} from "../../../lib/Client";
import {Product} from "../Product";
import Slider from "react-slick";
import {theme} from "../../../lib/theme";


function NextArrow(props) {
    const { className, style, onClick } = props;
    return <button type="button" className={className} style={{ ...style, right: "-10px"}} onClick={onClick}>
        <i className="fa fa-chevron-right"></i>
    </button>
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <button type="button" className={className} style={{ ...style, left: "-10px"}} onClick={onClick}>
        <i className="fa fa-chevron-left"></i>
    </button>
}


export const ProductsGroup = ({group}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [name, setName] = useState("");

    const fetch = () => {
        setLoading(true);
        return client.storeProductGroup(group).then((response) => {
            setProducts(response.data.products.map(item => item.product));
            setName(response.data.name);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching products group', error);
            setProducts([]);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetch();
    }, []);

    if (loading) {
        return <CircularProgress size="2rem"/>
    }
    if (products.length === 0) {
        return <></>
    }
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        centerPadding: '32px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                    //slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2
                    //slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                    //slidesToScroll: 1
                }
            }
        ]
    };
    while(products.length < 5) {
        products.push(...products);
    }

    return <Box sx={{marginTop: 2}}>
        <Typography variant="h5" component="div">
            {name}
        </Typography>
        <Slider {...settings}>
        {products.map((product) =>  <Box key={product.id} sx={{p: 2, height: "100%", boxSizing: "border-box"}}>
            <Product key={product.id} sx={{m:1}} product={product}/>
        </Box>)}
        </Slider>
    </Box>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <ProductsGroup {...props}/>
        </Suspense>
    </ThemeProvider>
}

class ProductsGroupElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-products-group', ProductsGroupElement)

export default ProductsGroupElement