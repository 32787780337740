import React from "react";
import {Avatar} from "@mui/material";


const UserAvatar = ({user}) => {
    if (user === null
        || user === undefined
        || typeof(user.first_name) === "undefined"
        || user.first_name === null
        || typeof(user.last_name) === "undefined"
        || user.last_name === null
    ) {
        return <Avatar>
            ?
        </Avatar>
    }
    let color = "male.main";
    if (user.gender === "female" || user.gender === "K") {
        color = "female.main";
    }
    const initials = `${user.first_name.substring(0,1)}${user.last_name.substring(0,1)}`
    return <Avatar sx={{ bgcolor: color }}>
        {initials}
    </Avatar>
}

export default UserAvatar;