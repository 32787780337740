import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, CircularProgress} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {client} from "../../../lib/Client";
import AddToCartDialog from "../AddToCartDialog";
import {refreshCart} from "../../../lib/utils";


export const AddToCart = ({label, data, cart, onAdd, ...other}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const {t} = useTranslation();

    if (label === null) {
        label = "Dodaj do koszyka";
    }
    const handleClick = (event) => {
        setLoading(true);

        const requests = [];
        const responses = [];
        let success = true;
        for (const item of data) {
            const request = client.createCartItem(item).then((response) => {
                responses.push(response.data);
            }, (error) => {
                success = false
            });
            requests.push(request);
        }

        return Promise.all(requests).then(() => {
            setLoading(false);
            if (!success) {
                return
            }
            refreshCart();
            setItems(responses);
            setOpen(true);
        })
    }

    const handleClose = () => {
        setOpen(false);
        if (onAdd) {
            onAdd();
        }
    }

    return <React.Fragment>
        <Button variant="contained" disabled={loading} {...other} onClick={handleClick} endIcon={loading ? <CircularProgress size="1em"/> : <AddShoppingCartIcon/>}>{label}</Button>
        <AddToCartDialog open={open} items={items} handleClose={handleClose}/>
    </React.Fragment>
}

AddToCart.defaultProps = {
    label: null
}