import React from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import {GroupAdd, Groups} from "@mui/icons-material";



const GroupBuyInfo = ({race, ...props}) => {
    const {t} = useTranslation();

    if (!race.event) {
        return <></>
    }
    const url = t("routes.groupBuy") + "/" + race.event.slug;


    return <div className="clearfix">
        <div className="pull-right groupbuyinfo-margin">
            <div>
                <span className="groupbuyinfo">{t('If you buy start for more than 1 person, use group buy')}</span>
                <Button
                    endIcon={<GroupAdd/>}
                    href={url}
                    variant="outlined"
                >{t('Group buy')}</Button>
            </div>
        </div>
    </div>
}


export default GroupBuyInfo;