import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, CircularProgress, Container, Typography} from "@mui/material";
import nl2br from "react-nl2br";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import {useSearchParams} from "react-router-dom";
import {client} from "../../lib/Client";
import {trackThankYou} from "../../lib/tracking";
import {ChevronRight} from "@mui/icons-material";
import CouponList from "../../components/CouponList";

const ThankYou = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const order_id = searchParams.get('order_id');
    const status = searchParams.get('status');

    const paymentSuccess = status === 'OK';

    const [order, setOrder] = useState(null);
    const [payment, setPayment] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [filteredCoupons, setFilteredCoupons] = useState([]);


    useEffect(() => {

        if (!paymentSuccess) {
            setLoading(false);
            return;
        } else {
            const promises = [];
            if (id) {
                promises.push(
                    client.userPayment(id).then(response => {
                        setPayment(response.data);
                    }, error => {
                        console.log(error);
                    })
                )
            }
            if (order_id) {

                promises.push(
                    client.userOrder(order_id).then(response => {
                        setOrder(response.data);

                    }, error => {
                        console.log(error);
                    })
                );
                promises.push(
                    client.userCoupons().then(response => {
                        setCoupons(response.data);
                    }, error => {
                        console.log(error);
                    })
                );
            }

            Promise.all(promises).then(() => {
                setLoading(false);
            })
        }

    }, [paymentSuccess]);

    useEffect(() => {
        if (!loading) {
            trackThankYou(payment, order);
        }
    }, [loading]);

    useEffect(() => {
        if (order && coupons) {
            const filtered = coupons.filter(c => c.details?.order_id == order.id);
            setFilteredCoupons(filtered);
        } else {
            setFilteredCoupons([]);
        }
    }, [order, coupons])

    const Icon = paymentSuccess ? <VerifiedTwoToneIcon
            sx={{fontSize: 100, color: "success.main", position: "absolute", "right": "-40px", top: "-40px"}}/> :
        <NewReleasesTwoToneIcon
            sx={{fontSize: 100, color: "error.main", position: "absolute", "right": "-40px", top: "-40px"}}/>

    const heading = paymentSuccess ? t('payment.headingSuccess') : t('payment.headingFailure')
    const content = paymentSuccess ? t('payment.contentSuccess') : t('payment.contentFailure')

    return <>
        <Container component="main" maxWidth="sm" sx={{mb: 4}}>
            {loading && <div style={{textAlign: "center"}}><CircularProgress size="2rem"/></div>}
            {!loading && <Box sx={{position: "relative", maxWidth: 520, mb: 5, mt: 2}}>
                {Icon}
                <Card sx={{maxWidth: 520}} variant="outlined">
                    <CardContent>
                        <Typography sx={{mb: 3}} variant="h5" align="center">{nl2br(heading)}</Typography>
                        <Typography
                            sx={{mb: 2, textAlign: "center"}}
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                        { filteredCoupons.length > 0 && <Button variant="contained" fullWidth endIcon={<ChevronRight/>} href="/odbior-koszulek-2024"
                                                                sx={{mt: 3}}>
                            Dokończ swój zapis
                        </Button> }
                        { filteredCoupons.length === 0 && <Button variant="contained" fullWidth endIcon={<ChevronRight/>} href={t('routes.user')}
                                sx={{mt: 3}}>
                            {t('payment.goToYourAccount')}
                        </Button> }
                    </CardContent>
                </Card>
            </Box>}
        </Container>
        {filteredCoupons.length > 0 &&
            <>
                <Box sx={{display: 'flex', justifyContent: 'center', mb: 1}}>
                    <Typography variant="h4" color={"primary"}>
                        Do Twojego konta został dopisany kod
                    </Typography>
                    <img src="/images/arrow_1.png" style={{height: "3em", marginTop: "0.5em", marginLeft: "1.25em"}} alt=""/>
                </Box>
                <CouponList coupons={filteredCoupons}/>
            </>}
    </>
}

export default ThankYou;