import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel, Typography
} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import InvoiceDialog from "../InvoiceDialog";
import {useTranslation} from "react-i18next";


const style = {
    display: "flex",
    justifyContent: "center"
}

const Invoice = ({cart, updateCart}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setOpen(true);
        } else {
            setLoading(true)
            updateCart({issue_invoice: false, invoice_data: {}}).then(() => {
                setLoading(false);
                handleClose();
            }).catch((error) => {
                setLoading(false);
            })
        }
    }

    if (!cart.payment_required) {
        return <></>
    }


    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.invoice')}
                </Typography>
                <Typography component="div">
                    {t('cart.invoiceDescription')}
                </Typography>
                <FormControlLabel
                    control={ <Checkbox checkedIcon={loading ? <CircularProgress size="1.7em"/> : <CheckBoxIcon />} checked={cart.issue_invoice} onChange={handleChange} />}
                    label={t("invoice.issueInvoice")} />

                <Box>
                    {cart.issue_invoice && <React.Fragment>

                    <div>{t('invoice.taxId')}: <b>{cart.invoice_data.tax_id}</b></div>
                    <div>{t('invoice.company')}: <b>{cart.invoice_data.company}</b></div>
                    <div>{cart.invoice_data.address}</div>
                    <div>{cart.invoice_data.zip_code} {cart.invoice_data.town}</div>
                    </React.Fragment>
                    }
                </Box>
            </CardContent>

        </Card>
        <InvoiceDialog cart={cart} open={open} handleClose={handleClose} updateCart={updateCart}/>
    </React.Fragment>
}

export default Invoice