import React, {useEffect, useRef, useState} from "react";
import {Box, Card, CardContent, Paper, Slide, Step, StepLabel, Stepper} from "@mui/material";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import {TransitionGroup} from "react-transition-group";


const AlreadyInCart = ({race}) => {
    return (
        <Box sx={{width: '100%', mb: 5}}>

            <Box sx={{mt: 2, mb: 1}}>

                <Paper sx={{p: 2}}>
                    <Box sx={{position: "relative", maxWidth: 520, ml: "auto", mr: "auto"}}>
                        <VerifiedTwoToneIcon
                            sx={{
                                fontSize: 100,
                                color: "success.main",
                                position: "absolute",
                                right: "-40px",
                                top: "-40px"
                            }}/>

                        <Card sx={{maxWidth: 520}} variant="outlined">
                            <CardContent>
                                Start w tym biegu już jest w Twoim koszyku
                            </CardContent>
                        </Card>
                    </Box>
                </Paper>

            </Box>
        </Box>
    );
}

export default AlreadyInCart;