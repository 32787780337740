import React from 'react';
import {formatMoney} from "../../../lib/utils";
import {Button, Card, CardContent, Typography} from "@mui/material";
import { useTranslation, Trans } from 'react-i18next';


const CartSummary = ({cart}) => {
    const { t } = useTranslation();

    return <React.Fragment>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.summary')}
                </Typography>

                {cart.shipping_price.amount != 0 && <Typography component="div">
                    {t('cart.shipping')}: <b>{formatMoney(cart.shipping_price)}</b>
                </Typography> }
                <Typography variant="h5" component="div">
                    {t('cart.total')}: <b>{formatMoney(cart.total)}</b>
                </Typography>
            </CardContent>

        </Card>
    </React.Fragment>
}

export default CartSummary