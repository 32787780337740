import React, {Component, useEffect, useState} from "react";
import {Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, FormGroup} from "@mui/material";
import {client} from "../../../lib/Client";
import {Loading} from "../../Loading";
import {prepareLabel} from "../../../lib/utils";

export const ClausesList = ({value, race, onSelect}) => {
    const [loading, setLoading] = useState(false)
    const [clauses, setClauses] = useState([]);
    const [checked, setChecked] = useState(value !== null ? value : []);

    const fetchClauses = () => {
        setLoading(true);
        client.clauses(race.id).then((response) => {
            setClauses(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching', error)
        });
    }

    useEffect(() => {
        setClauses([]);
        if (race === null) {
            return;
        }
        fetchClauses();
    }, [race]);

    const handleChange = (clause) => (event) => {
        if (event.target.checked && !checked.includes(clause.id)) {
            setChecked(checked.concat([clause.id]));
        }
        if (!event.target.checked && checked.includes(clause.id)) {
            setChecked(checked.filter((c) => c !== clause.id));
        }

    };
    useEffect(() => {
        if (clauses.length === 0) {
            return;
        }
        for (const clause of clauses) {
            if (clause.is_required && !checked.includes(clause.id)) {
                onSelect(null);
                return;
            }
        }
        onSelect(checked);
    }, [checked]);


    if (loading) {
        return <Loading></Loading>
    }

    return (
        <React.Fragment>
            <FormGroup>
                {clauses.map((clause, index) => {

                        const content = <span dangerouslySetInnerHTML={{__html: prepareLabel(clause.content)}}/>
                        return <FormControlLabel
                            key={clause.id}
                            required={clause.is_required}
                            label={content}
                            disableTypography={false}
                            sx={{mb: 2}}
                            control={
                            <Checkbox onChange={handleChange(clause)}
                                      checked={checked.includes(clause.id)}

                            />}
                        />
                    }
                )}
            </FormGroup>
        </React.Fragment>
    );
}