import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";

import {ThemeProvider} from "@mui/material";
import {theme} from "./lib/theme";
import store from './store'
import { Provider } from 'react-redux'

import App from "./app";
import HeaderButtons from "./components/HeaderButtons";
const AdminApp = lazy(() => import("./admin/app"))
// import AdminApp from "./admin/app"

if (window.location.host === 'localhost') {
    // horrible
    __webpack_public_path__ = "http://localhost:3000/"
}

document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById("root");
    if (root === null) {
        return;
    }
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <Suspense fallback=" ">
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </Suspense>
            </ThemeProvider>
        </React.StrictMode>,
        root
    );
});
// Cart count, user nav
document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById("header-btns");
    if (root === null) {
        return;
    }
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <Suspense fallback=" ">
                    <Provider store={store}>
                        <HeaderButtons/>
                    </Provider>
                </Suspense>
            </ThemeProvider>
        </React.StrictMode>,
        root
    );
});
document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById("admin_root");
    if (root === null) {
        return;
    }
    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback="">
                <AdminApp/>
            </Suspense>
        </React.StrictMode>,
        root
    );
})