import React from "react";
import InsuranceItem from "../InsuranceItem";
import {List, Paper, Typography} from "@mui/material";

function InsuranceProduct({product, children}) {

    return  <Paper elevation={3}  sx={{p:2}}>
        <Typography variant="h6">{product.name}</Typography>
        {children}
    </Paper>



}

export default InsuranceProduct;