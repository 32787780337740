import React, {useEffect, useState} from "react";

import * as yup from "yup";
import {useFormik} from "formik";
import {client} from "../../../lib/Client";
import {Box, Button, CircularProgress, Stack, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import KidGenderSelect from "../../Base/KidGenderSelect";
import DateSelect from "../../DateSelect";
import AddIcon from "@mui/icons-material/Add";
import cloneDeep from "clone-deep";
import {fetchKids} from "../../../features/kids/kidsSlice";
import {useDispatch} from "react-redux";


const KidCreateForm = ({onSuccess}) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    const validationSchema = yup.object({
        first_name: yup.string().required().label(t("First name")),
        last_name: yup.string().required().label(t("Last name")),
        gender: yup.string().nullable().required().label(t("Gender")),
        birth_date: yup.date().nullable().required().label(t("Birth date")),
        pesel: yup.string().pesel().required().label(t("PESEL"))
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            gender: null,
            birth_date: null,
            pesel: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const cloned = cloneDeep(values)
            cloned.birth_date = cloned.birth_date.toISODate();
            return client.userKidsCreate(cloned).then(response => {
                // const {order, payment, online_payment} = response.data
                dispatch(fetchKids()).then(() => {
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                })

            }).catch(err => {
                // setProcessing(false)
            });
        },
    });

    const handleKeyDown = e => {
        if (e.code.substring(0,3) === "Key" && !e.ctrlKey) {
            e.preventDefault();
        }
    };

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <TextField
                    variant="outlined"
                    autoComplete="nope"
                    label={t("First name")}
                    name="first_name"
                    required
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                />
                <TextField
                    variant="outlined"
                    autoComplete="nope"
                    label={t("Last name")}
                    name="last_name"
                    required
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                />
                <KidGenderSelect
                    name={"gender"}
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                    helperText={formik.touched.gender && formik.errors.gender}
                ></KidGenderSelect>
                <DateSelect
                    autoComplete="nope"
                    label={t("Birth date")}
                    name="birth_date"
                    required
                    value={formik.values.birth_date}
                    onChange={formik.handleChange}
                    error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                    helperText={formik.touched.birth_date && formik.errors.birth_date}
                    // maxDate
                    />
                <TextField
                    variant="outlined"
                    autoComplete="nope"
                    label={t("PESEL")}
                    name="pesel"
                    inputProps={{
                        maxLength: 11,
                        inputMode: "numeric",
                        onKeyDown: handleKeyDown
                    }}
                    required
                    value={formik.values.pesel}
                    onChange={formik.handleChange}
                    error={formik.touched.pesel && Boolean(formik.errors.pesel)}
                    helperText={formik.touched.pesel && formik.errors.pesel}
                />
                <Button endIcon={formik.isSubmitting ? <CircularProgress size="1em"/> : <AddIcon/>}
                        disabled={formik.isSubmitting}
                        variant="contained"
                        fullWidth={true}
                        // onClick={ev => { console.log(formik.values)}}
                        onClick={formik.handleSubmit}
                        >
                    {t('Create')}
                </Button>
            </Stack>
        </React.Fragment>
    )
}
export default KidCreateForm;