import { configureStore } from '@reduxjs/toolkit'
import alertsReducer from './features/alerts/alertsSlice'
import settingsReducer from './features/settings/settingsSlice'
import upcomingEventReducer from './features/upcoming-event/upcomingEventSlice'
import counterReducer from './features/counter/counterSlice'
import cartReducer from './features/cart/cartSlice'
import userReducer from './features/user/userSlice'
import kidsReducer from './features/kids/kidsSlice'

export default configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        alerts: alertsReducer,
        settings: settingsReducer,
        upcomingEvent: upcomingEventReducer,
        counter: counterReducer,
        kids: kidsReducer
    },
})
