import React, {useState} from "react";
import {
    Box,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SendIcon from "@mui/icons-material/Send";

const InvoiceDialog = ({cart, open, handleClose, updateCart}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const defaultValues = {}
    if (!Array.isArray(cart.invoice_data)) {
        Object.assign(defaultValues, cart.invoice_data);
    }
    const [invoiceData, setInvoiceData] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const handleDataChange = (field) => (event) => {
        const newData = {}
        Object.assign(newData, invoiceData);
        newData[field] = event.target.value;
        setInvoiceData(newData);
    }
    const handleSubmit = (event) => {
        setLoading(true);

        const data = {
            issue_invoice: true,
            invoice_data: invoiceData
        }

        updateCart(data).then(() => {
            setLoading(false);
            handleClose();
        }).catch((error) => {
            setLoading(false);
            if (error.response) {
                const error_messages = {};
                Object.keys(error.response.data["errors"]).forEach((key, index) => {
                    error_messages[key] = Object.values(error.response.data["errors"][key]).join(". ")
                });
                setErrors(error_messages);
            }
        })
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                {t('invoice.invoiceData')}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    error={"tax_id" in errors}
                    helperText={"tax_id" in errors ? errors["tax_id"] : ""}
                    label={t("invoice.taxId")}
                    fullWidth
                    variant="outlined"
                    onChange={handleDataChange("tax_id")}
                />
                <TextField
                    error={"company" in errors}
                    helperText={"company" in errors ? errors["company"] : ""}
                    label={t("invoice.company")}
                    fullWidth
                    variant="outlined"
                    onChange={handleDataChange("company")}
                />
                <TextField
                    error={"address" in errors}
                    helperText={"address" in errors ? errors["address"] : ""}
                    label={t("invoice.address")}
                    fullWidth
                    variant="outlined"
                    onChange={handleDataChange("address")}
                />
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 0, width: '15ch', marginRight:1, }}>
                        <TextField
                            error={"zip_code" in errors}
                            helperText={"zip_code" in errors ? errors["zip_code"] : ""}
                            label={t("invoice.zipCode")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("zip_code")}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, }}>
                        <TextField
                            error={"town" in errors}
                            helperText={"town" in errors ? errors["town"] : ""}
                            label={t("invoice.town")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("town")}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"}>{t('cancel')}</Button>
                <Button onClick={handleSubmit} startIcon={loading && <CircularProgress size="1em"/>} endIcon={<SendIcon/>}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoiceDialog