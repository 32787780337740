
import * as React from 'react';
import {Alert} from "@mui/material";
import Coupon from "../Coupon";



const CouponList = ({coupons}) => {

    if (coupons.length === 0) {
        return <Alert severity="warning">Nie masz jeszcze zniżek</Alert>
    }
    return (
        <>
            {coupons.map( coupon => <Coupon key={coupon.code} coupon={coupon}/>)}
        </>
    );
}
export default CouponList;