import {AddToCart} from "../AddToCart";
import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense} from "react";
import {ThemeProvider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {theme} from "../../../lib/theme";


export const AddToCartProduct = ({product_id}) => {
    const {t} = useTranslation();

    return <AddToCart size="large" variant="contained" data={[{type: "product", product_id: product_id}]}/>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <AddToCartProduct {...props}/>
        </Suspense>
    </ThemeProvider>
}

class AddToCartProductElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-add-to-cart-product', AddToCartProductElement)

export default AddToCartProductElement