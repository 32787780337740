import React, { useEffect, useState} from "react";
import {Avatar, ListItemAvatar, Typography} from "@mui/material";
import {Loading} from "../../Loading";
import {client} from "../../../lib/Client";
import {calculateAgeAtDate, DateDiff, formatDate} from "../../../lib/utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from '@mui/material/List';
import Clock from "../../Clock";
import {useTranslation} from "react-i18next";
import {Girl} from "@mui/icons-material";


export const NumberOfParticipantsSelect = ({value, race, onSelect}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const [series, setSeries] = useState([]);

    const fetchSeries = () => {
        setLoading(true);
        client.series(race.id).then((response) => {
            setSeries(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching series', error)
        });
    }

    useEffect(() => {
        setSeries([]);
        if (race === null) {
            return;
        }
        fetchSeries();
    }, [race]);

    const handleClick = (series) => () => {
        onSelect(series);
    }
    if (loading) {
        return <>
            <Loading/>
        </>
    }

    let options = [];
    for (const serie of series) {
        options.push(serie.number_of_participants);
    }
    options = [...new Set(options)];


    return <List variant={"grid"} dense={true} >{options.map((item) => {
        const labelId = `checkbox-list-label-${item}`;
        const primary = t('person', {count: item});
        const disabled = false;
        return (
            <ListItem
                key={item}
                disablePadding
            >
                <ListItemButton onClick={handleClick(item)} dense selected={value === item} disabled={disabled}>
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: "primary.main"}}>
                            {item}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={primary}/>
                </ListItemButton>
            </ListItem>
        );
    })}
    </List>

}