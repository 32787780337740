import CartItem from "../CartItem";
import {Collapse, Stack} from "@mui/material";
import React from "react";
import { TransitionGroup } from 'react-transition-group';


const CartItems = ({cart, removeItem, patchItem}) => {
    const sortCallback = (a,b) => {
        if (a.discriminator === "SuntagoOrderItem" && b.discriminator === "SuntagoOrderItem") {
            return 0;
        }
        if (a.discriminator !== "SuntagoOrderItem" && b.discriminator === "SuntagoOrderItem") {
            return -1;
        }
        if (a.discriminator === "SuntagoOrderItem" && b.discriminator !== "SuntagoOrderItem") {
            return 1;
        }
        return 0;
    }

    const items = Array.from(cart.items);
    items.sort(sortCallback);

    return <TransitionGroup>
        {items.map((item, index) => {
            return <Collapse key={item.id} sx={{mb: 2}}>
                <CartItem item={item} removeItem={removeItem} patchItem={patchItem}/>
            </Collapse>
        })}
        </TransitionGroup>
}

export default CartItems;