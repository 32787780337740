import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Alert, Typography} from "@mui/material";

const Alerts  = () => {
    const alerts = useSelector((state) => state.alerts.alerts)
    const dispatch = useDispatch()

    return (
        <>
        {alerts.map( (item, key) => <Alert
            sx={{mb: 1}}
            key={key}
            severity={item.severity}
             >
            <Typography dangerouslySetInnerHTML={{__html: item.message}}/>
        </Alert> )}

        </>
    )
}

export default Alerts;
/*
<div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(add({"message":"lorem", "serverity":"error"}))}
                >
                    Increment
                </button>
            </div>
 */