import React, { useEffect, useState} from "react";
import { ListItemAvatar, Typography} from "@mui/material";
import {Loading} from "../../Loading";
import {client} from "../../../lib/Client";
import {calculateAgeAtDate, DateDiff, formatDate} from "../../../lib/utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from '@mui/material/List';
import Clock from "../../Clock";
import {useTranslation} from "react-i18next";


export const SeriesSelect = ({value, race, kid, numberOfParticipants, onSelect}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const [series, setSeries] = useState([]);

    const fetchSeries = () => {
        setLoading(true);
        client.series(race.id).then((response) => {
            setSeries(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching series', error)
        });
    }

    useEffect(() => {
        setSeries([]);
        if (race === null) {
            return;
        }
        fetchSeries();
    }, [race]);

    const handleClick = (series) => () => {
        onSelect(series);
    }
    if (loading) {
        return <>
            <Loading/>
        </>
    }

    return <List variant={"grid"} dense={true} >{series
        .filter((serie) => numberOfParticipants === null || serie.number_of_participants === numberOfParticipants)
        .map((serie) => {
        const labelId = `checkbox-list-label-${serie.id}`;
        const free = Math.max(0, serie.number_of_places - serie.signed_up)
        const dt = new Date(`${serie.date} ${serie.time}`)
        let primary = <>{serie.time} {formatDate(dt, false)}</>
        if (serie.name) {
            primary = <><Typography sx={{}}>{serie.name}</Typography>{primary}</>
        }
        const secondary = t('freePlaces', {count: free});
        let disabled = false;
        if (kid) {
            const age = calculateAgeAtDate(kid.birth_date, serie.date);
            if (serie.minimum_age && age < serie.minimum_age) {
                disabled = true;
            }
            if (serie.maximum_age && age > serie.maximum_age) {
                disabled = true;
            }
        }

        if (free <= 0) {
            disabled = true;
        }
        return (
            <ListItem
                key={serie.id}
                disablePadding
            >
                <ListItemButton onClick={handleClick(serie)} dense selected={value === serie.id} disabled={disabled}>
                    <ListItemAvatar>
                        <Clock size={40} value={dt} renderSecondHand={false}></Clock>
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={primary} secondary={secondary}/>
                </ListItemButton>
            </ListItem>
        );
    })}
    </List>

}