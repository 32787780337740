import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loading: true,
        cart: {}
    },
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = true
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCart, setLoading } = cartSlice.actions

export default cartSlice.reducer
