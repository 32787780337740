import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Avatar,
    Button, ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    ListItemAvatar, Stack, TextField,
    Typography
} from "@mui/material";
import {Loading} from "../../Loading";
import {client} from "../../../lib/Client";
import {calculateAgeAtDate, DateDiff, formatDate} from "../../../lib/utils";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from '@mui/material/List';
import Clock from "../../Clock";
import {useTranslation} from "react-i18next";
import {Boy, Check, Girl, Man, Person} from "@mui/icons-material";
import {useSelector, useStore} from "react-redux";
import UserAvatar from "../../Common/UserAvatar";
import KidsSelect from "../KidsSelect";
import cloneDeep from "clone-deep";
import * as yup from "yup";
import debounce from "debounce-async";
import {useFormik} from "formik";
import {selectKidById} from "../../../features/kids/kidsSlice";


const CurrentParticipant = () => {
    const {authenticated, user} = useSelector((state) => state.user)

    return <Card sx={{w: "100%"}} variant="outlined">
        <CardHeader
            avatar={
                <UserAvatar user={user}></UserAvatar>
            }
            title={`Główny uczestnik`}
            subheader={`${user.first_name} ${user.last_name}`}
        />
        <CardContent>

        </CardContent>
    </Card>;
}


const controlledCheckValidUser = (key) => async (value) => {
    if (!yup.string().required().email().isValidSync(value)) {
        return false;
    }
    try {
        const response = await client.uniqueEmail(value, key);
        return !response.data.unique;
    } catch (e) {
        return false;
    }
}

export const Participants = ({value, race, numberOfParticipants, onChange}) => {
    const {t} = useTranslation();
    const store = useStore();
    const {user} = useSelector((state) => state.user)


    let validationSchema = yup.object({});
    const initial = {};
    const indices = [];
    for (let i = 2; i <= numberOfParticipants; i++) {
        indices.push(i);
    }
    indices.forEach(i => {
        initial[`participant_${i}_type`] = "";
        initial[`participant_${i}_user`] = "";
        initial[`participant_${i}_kid`] = "";

        if (value && value[`participant_${i}_type`]) {
            initial[`participant_${i}_type`] = value[`participant_${i}_type`]
        }
        if (value && value[`participant_${i}_user`]) {
            initial[`participant_${i}_user`] = value[`participant_${i}_user`]
        }
        if (value && value[`participant_${i}_kid`]) {
            initial[`participant_${i}_kid`] = value[`participant_${i}_kid`]
        }
        const shaped = {}
        shaped[`participant_${i}_type`] = yup.string().required().oneOf(['adult', 'kid']).label(t('Participant type'));
        shaped[`participant_${i}_user`] = yup.string().when(`participant_${i}_type`, {
                is: 'adult',
                then: (schema) => schema.sequence([
                    () => yup.string().required("Pole wymagane"),
                    () => yup.string().email("Podaj poprawny adres email"),
                    () => yup.string().test(`unique-adult-${i}`, 'Dorośli biorący udział muszą być unikalkni',
                        (value, context) => {
                            const n = parseInt(context.path.replace(/[^0-9]/g, ''));
                            const other = [user.email];
                            for (let j = 2; j < n; j++) {
                                if (context.parent[`participant_${j}_type`] === 'adult') {
                                    other.push(context.parent[`participant_${j}_user`])
                                }
                            }
                            return !other.includes(value);
                        }
                    ),
                ])
            }
        ).label(t('Adult'))
        shaped[`participant_${i}_kid`] = yup.string().when(`participant_${i}_type`, {
                is: 'kid',
                then: (schema) => schema.required().test(`unique-kid-${i}`, 'Dzieci biorące udział muszą być unikalne',
                    (value, context) => {
                        const n = parseInt(context.path.replace(/[^0-9]/g, ''));
                        const other = [];
                        for (let j = 2; j < n; j++) {
                            if (context.parent[`participant_${j}_type`] === 'kid') {
                                other.push(context.parent[`participant_${j}_kid`])
                            }
                        }
                        return !other.includes(value);
                    }
                )
            }
        ).label(t('Child 4-15 years old'))

        validationSchema = validationSchema.shape(shaped)

    });

    const formik = useFormik({
        initialValues: initial,
        validationSchema: validationSchema,
        // validateOnMount: true,
        // enableReinitialize: false,
        // isInitialValid: false
    });
    useEffect(() => {
        formik.validateForm();
    }, []);
    useEffect(() => {
        if (formik.isValid) {
            onChange(formik.values);
        } else {
            onChange(null);
        }
    }, [formik.isValid]);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} xl={3} key={1}><CurrentParticipant></CurrentParticipant></Grid>
                {Array(numberOfParticipants - 1).fill(null).map((value, i) => {
                    const index = i + 2;
                    let subheader = <em>Wybierz uczestnika</em>;
                    if (formik.values[`participant_${index}_type`] === "kid" && formik.values[`participant_${index}_kid`]) {
                        const kid = selectKidById(store.getState(), formik.values[`participant_${index}_kid`]);
                        subheader = `${kid.first_name} ${kid.last_name}`;
                    }
                    if (formik.values[`participant_${index}_type`] === "adult"
                        && formik.values[`participant_${index}_user`]
                        && !(formik.errors[`participant_${index}_user`])
                    ) {
                        subheader = formik.values[`participant_${index}_user`];
                    }

                    return (
                        <Grid item xs={12} md={4} xl={3} key={index}>
                            <Card sx={{w: "100%"}}>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            {index}
                                        </Avatar>
                                    }
                                    // action={}
                                    title={`Uczestnik ${index}`}
                                    subheader={subheader}
                                />
                                <CardContent>
                                    <Stack spacing={2}>
                                        <ButtonGroup size="small" fullWidth={false}>
                                            <Button
                                                variant={formik.values[`participant_${index}_type`] === "adult" ? "contained" : "outlined"}
                                                startIcon={<Man/>}
                                                name={`participant_${index}_type`}
                                                value={"adult"}
                                                onClick={formik.handleChange}
                                            >{t('Adult')}</Button>
                                            <Button
                                                variant={formik.values[`participant_${index}_type`] === "kid" ? "contained" : "outlined"}
                                                endIcon={<Boy/>}
                                                name={`participant_${index}_type]`}
                                                value={"kid"}
                                                onClick={formik.handleChange}
                                            >{t('Child 4-15 years old')}</Button>
                                        </ButtonGroup>
                                        {formik.values[`participant_${index}_type`] === "adult" && <TextField
                                            autoComplete="nope"
                                            label={t("Enter adult user's email address")}
                                            name={`participant_${index}_user`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[`participant_${index}_user`]}
                                            error={formik.touched[`participant_${index}_user`] && Boolean(formik.errors[`participant_${index}_user`])}
                                            helperText={formik.touched[`participant_${index}_user`] && formik.errors[`participant_${index}_user`]}
                                            InputProps={{
                                                endAdornment: formik.touched[`participant_${index}_user`] && !(formik.errors[`participant_${index}_user`]) ?
                                                    <Check color="success"/> : <></>
                                            }}
                                        ></TextField>}
                                        {formik.values[`participant_${index}_type`] === "kid" && <KidsSelect
                                            race={race}
                                            fullWidth={true}
                                            label={t('Child 4-15 years old')}
                                            value={formik.values[`participant_${index}_kid`]}
                                            name={`participant_${index}_kid]`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched[`participant_${index}_kid`] && Boolean(formik.errors[`participant_${index}_kid`])}
                                            helperText={formik.touched[`participant_${index}_kid`] && formik.errors[`participant_${index}_kid`]}

                                        ></KidsSelect>}
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </React.Fragment>
    )


}