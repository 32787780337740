import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, Step, StepLabel, Typography} from "@mui/material";
import React, {useRef} from "react";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import Introduce from "./Introduce";
import Welcome from "./Welcome";
import * as yup from "yup";
import {client} from "../../../lib/Client";
import {useFormik} from "formik";
import debounce from "debounce-async";
import {matchIsValidTel} from "mui-tel-input";

const {useState} = require("react");
const {Stepper} = require("@mui/material");

const cacheTest = (asyncValidate) => {
    let _valid = false;
    let _value = '';

    return async (value) => {

        if (value !== _value) {
            const response = await asyncValidate(value);
            _value = value;
            _valid = response;
            return response;
        }
        return _valid;
    };
};

const checkEmailUnique = async (value) => {
    if (!yup.string().required().email().isValidSync(value)) {
        return false;
    }
    try {
        const response = await client.uniqueEmail(value);
        return response.data.unique;
    } catch (e) {
        return false;
    }
}

// const debouncedCheckEmailUnique = debounce(async (searchTerm) => {
//     return await checkEmailUnique(searchTerm);
// }, 300);
const debouncedCheckEmailUnique = debounce(checkEmailUnique, 300);


const RegisterForm = () => {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = useState(0);

    const emailUniqueTest = useRef(cacheTest(debouncedCheckEmailUnique));


    const phoneTest = (value) => {
        if (typeof value !== "string") {
            return false;
        }

        return matchIsValidTel(value);
    }

    const validationSchema = yup.object({
        firstName: yup.string().required().label(t('user.firstName')),
        lastName: yup.string().required().label(t('user.lastName')),
        email: yup.string().required().email().test('checkEmailUnique', t('validation.uniqueEmail'), emailUniqueTest.current).label(t('user.email')),
        password: yup.string().required().min(8).label(t('user.password')),
        // sports: yup.array().required(),
        // findOut: yup.number().required()
        phoneNumber: yup.string().required().test('isValidTel', t('validation.phoneNumber'), phoneTest).label(t('user.phoneNumber')),
        processing: yup.boolean().oneOf([true], t('validation.consent')),
        consent: yup.boolean().oneOf([true], t('validation.consent')),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneNumber: '',
            processing: false,
            consent: false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            return client.register(values).then(response => {
                setActiveStep(1);
            }).catch(err => {

            });
        },
    });


    const handleStep = (step, formik) => {
        switch (step) {
            case 0:
                return <Introduce formik={formik}/>
            case 1:
                return <Welcome/>
        }
    }

    return <>
        <Box sx={{mb: 5, mt: 2}}>
            <Stepper activeStep={activeStep}>
                <Step key="introduce">
                    <StepLabel>{t('user.register.steps.introduce')}</StepLabel>
                </Step>
                <Step key="welcome">
                    <StepLabel>{t('user.register.steps.welcome')}</StepLabel>
                </Step>
            </Stepper>
        </Box>
        <Box sx={{position: "relative", maxWidth: 520}}>
            { activeStep === 1 && <VerifiedTwoToneIcon
                sx={{fontSize: 100, color: "success.main", position: "absolute", "right": "-40px", top: "-40px"}}/>
            }
            <Card sx={{maxWidth: 520}} variant="outlined">
                <CardContent>
                    {handleStep(activeStep, formik)}
                </CardContent>
            </Card>
        </Box>
    </>
}

export default RegisterForm;