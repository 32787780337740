import {Typography} from "@mui/material";
import {formatMoney} from "../../../lib/utils";
import React from "react";

const OmnibusPrice = ({price}) => {
    return <>
        <Typography variant="div" sx={{color: "darkgray", whiteSpace: "wrap"}}>najniższa cena z ostatnich 30 dni: </Typography>
        <Typography variant="div" sx={{color: "gray"}}>
            {formatMoney(price.price)}
        </Typography>
    </>
}

export default OmnibusPrice;