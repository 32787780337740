import React, {useEffect, useRef, useState} from "react";
import {Box, Card, CardContent, Paper, Slide, Step, StepLabel, Stepper, Typography} from "@mui/material";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import GppBadIcon from '@mui/icons-material/GppBad';
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import {KIDS_SHIRT_SIZES} from "../../../constants";

const CannotSignUp = ({race, errors}) => {
    return (
        <Box sx={{width: '100%', mb: 5}}>
            <Box sx={{mt: 2, mb: 1}}>
                    <Box sx={{position: "relative", maxWidth: 960, ml: "auto", mr: "auto"}}>
                        <NewReleasesTwoToneIcon
                            sx={{
                                fontSize: 120,
                                color: "error.main",
                                position: "absolute",
                                right: "-32px",
                                top: "-32px",
                                transform: "rotate(15deg)"
                            }}/>

                        <Card sx={{ pr: 8}} variant="outlined">
                            <CardContent>
                                {errors.map((error, index) => {
                                    return <Typography sx={{mb: 2}} key={index}  dangerouslySetInnerHTML={{__html: error}}/>
                                })}
                            </CardContent>
                        </Card>
                    </Box>
            </Box>
        </Box>
    );
}

export default CannotSignUp;