import {Card, CardContent, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import RegisterForm from "../../components/Forms/RegisterForm";
import React from "react";


const Register = () => {
    const {t} = useTranslation();
    return <>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <RegisterForm/>


        </Container>
    </>
}

export default Register;