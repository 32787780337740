import React, {useEffect, useState} from 'react';
import InsuranceProduct from "./InsuranceProduct";
import {client} from "../../../lib/Client";
import {Loading} from "../../Loading";
import {
    Box, Button, ButtonGroup, Checkbox, FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText,
    Paper, Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import InsuranceItem from "./InsuranceItem";
import {formatMoney} from "../../../lib/utils";
import {Done, NotInterested} from "@mui/icons-material";
import YesNoToggle from "../../YesNoToggle";


const Insurance = ({value, race, series, kid, withoutShirt, onChange}) => {
    const {t} = useTranslation();
    const defaultInterested = value !== null && value !== false ? true: value;
    const [interested, setInterested] = useState(defaultInterested);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [itemsTraining, setItemsTraining] = useState([]);

    const [chosen, setChosen] = useState(value ? value.race: []);
    const [chosenTraining, setChosenTraining] = useState(value ? value.training: []);
    const [agreementMarketing, setAgreementMarketing] = useState(false);

    const seriesId = series ? series.id : null;
    const kidId = kid ? kid.id : null;

    useEffect(() => {
        if (!interested) {
            setItems([]);
            return;
        }
        setLoading(true);

        const first = client.insuranceEventQuotes(race.id, seriesId, kidId, withoutShirt).then(response => {
            setItems(response.data.quotes);
        }, error => {
            console.log(error);
        });
        const second = client.insuranceTrainingQuotes(kidId).then(response => {
            setItemsTraining(response.data.quotes);
        }, error => {
            console.log(error);
        });
        Promise.all([first, second]).then(() => {
            setLoading(false);
        })


    }, [interested, series, withoutShirt, kid]);


    function extracted(items) {
        const itemsByProduct = {};
        for (let i in items) {
            let item = items[i]
            if (!(item['product_id'] in itemsByProduct)) {
                itemsByProduct[item['product_id']] = {
                    "id": item['product_id'],
                    "name": item['product_name'],
                    "items": []
                }
            }
            itemsByProduct[item['product_id']]["items"].push(item);
        }
        return Object.values(itemsByProduct);
    }

    const itemsByProduct = extracted(items);
    const itemsTrainingByProduct = extracted(itemsTraining);

    function toggleChosen(value) {
        if (chosen.includes(value)) {
            const new_chosen = chosen.filter(function (ele) {
                return ele !== value;
            });
            setChosen(new_chosen);
        } else {
            const new_chosen = chosen.concat([value]);
            setChosen(new_chosen);
        }
    }

    function toggleChosenTraining(value) {
        if (chosenTraining.includes(value)) {
            const new_chosen = chosenTraining.filter(function (ele) {
                return ele !== value;
            });
            setChosenTraining(new_chosen);
        } else {
            let new_chosen = chosenTraining
            for (let i in itemsTrainingByProduct) {
                let product = itemsTrainingByProduct[i];
                let item_ids = product.items.map((item) => item.id);
                if (item_ids.includes(value)) {
                    new_chosen = chosenTraining.filter(function (ele) {
                        return !item_ids.includes(ele);
                    });
                }
            }
            new_chosen = new_chosen.concat([value]);
            setChosenTraining(new_chosen);
        }
    }

    const handleChange = (event, newValue) => {
        setInterested(newValue);
    }
    const handleAgreementChange = (event, newValue) => {
        setAgreementMarketing(newValue);
    }

    useEffect(() => {
        let insurance = null;
        if (interested === false) {
            insurance = false;
        } else {
            if(agreementMarketing && (chosen.length > 0 || chosenTraining.length > 0)) {
                insurance = {
                    race: chosen,
                    training: chosenTraining,
                    agreementMarketing: agreementMarketing
                }
            }
        }
        onChange(insurance);

    }, [interested, chosen, chosenTraining, agreementMarketing]);

    return (
        <Stack spacing={2}>
            <Box>
                Czy jesteś zainteresowany ubezpieczeniem na zawody w zakresie następstwa nieszczęśliwego wypadku / zwrotu
                kosztów rehabilitacji/ zwrotu kosztów rezygnacji z udziału?
            </Box>
            <YesNoToggle value={interested} onChange={handleChange}></YesNoToggle>
            { interested && loading && <Loading/> }

            {interested && !loading &&
                <Stack spacing={2}>
                    <Typography variant={"h5"}>Wybierz ubezpieczenie na zawody</Typography>
                    <Grid container spacing={2} alignItems="stretch">
                        {itemsByProduct.map((product =>  <Grid item key={product.id} md={4}>
                            <InsuranceProduct product={product}>
                                <List>
                                    {product.items.map((item => {
                                        return <InsuranceItem key={item.id} item={item} toggle={toggleChosen} checked={chosen}></InsuranceItem>
                                    }))}
                                </List>
                            </InsuranceProduct>
                        </Grid>))}
                    </Grid>
                    <Typography variant={"h5"}>lub ubezpiecz się na 12 miesięcy</Typography>
                    <Grid container spacing={2} alignItems="stretch">
                        {itemsTrainingByProduct.map((product =>  <Grid item key={product.id} md={4}>
                            <InsuranceProduct product={product}>
                                <List>
                                    {product.items.map((item => {
                                        return <InsuranceItem key={item.id} item={item} toggle={toggleChosenTraining} checked={chosenTraining}></InsuranceItem>
                                    }))}
                                </List>
                            </InsuranceProduct>
                        </Grid>))}
                    </Grid>

                    <FormControlLabel
                        required={true}
                        control={<Checkbox checked={agreementMarketing} onChange={handleAgreementChange}/>}
                        label={<Typography variant="small">Wyrażam zgodę na przetwarzanie danych osobowych przez
                        UNIQA Towarzystwo Ubezpieczeń S.A. w celu otrzymania komunikacji marketingowej drogą mailową.
                        Zgodę można wycofać w dowolnym momencie.<br/>
                        Wycofanie zgody ma skutek tylko na przyszłość i nie ma wpływu na zgodność z prawem dokonanego
                        już przetwarzania,</Typography>}
                    />
                    <Typography variant="small">Partner ubezpieczeniowy UNIQA S.A.</Typography>
                    <Typography variant="small">Agentem ubezpieczeniowym jest Ubezpieczenia dla Aktywnych Sp. z o.o. (UDAO) agent UNIQA S.A.</Typography>

                </Stack>
            }

        </Stack>
    );
}


export default Insurance