import {
    Avatar,
    FormControl,
    FormHelperText,
    InputLabel,
    List,
    ListItemAvatar,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {useState} from "react";
import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {KIDS_SHIRT_SIZES} from "../../../constants";



const KidsShirtSizeList = ({name, value, onChange, ...props}) => {

    const [selectValue, setSelectValue] = useState(value);

    const handleClick = (newValue) => (ev) => {
        setSelectValue(newValue);
        if (onChange) {
            onChange(ev, newValue);
        }
    }
    return (
        <React.Fragment>
            <Typography>Wybierz rozmiar koszulki</Typography>
            <List
                variant="grid"
                dense={true}
                sx={{}}
                {...props}

            >
                {KIDS_SHIRT_SIZES.map((size) => {
                    const labelId = `kids-shirt-size-label-${size}`;
                    return <ListItem
                        key={size}
                        disablePadding
                    >
                        <ListItemButton selected={selectValue === size} onClick={handleClick(size)} dense>
                            <ListItemAvatar>
                                <Avatar>

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={size}/>
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
        </React.Fragment>
    );

}


export default KidsShirtSizeList;