import React, {useEffect, useRef, useState} from "react";
// import EventsList from "../EventsList";
// import RacesList from "../RacesList";
// import {SeriesSelect} from "../SeriesSelect";
import {
    Box, Button, Card, CardContent, Collapse,
    createTheme,
    MobileStepper, Paper,
    ScopedCssBaseline, Slide,
    Step,
    StepContent, StepIcon,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import CssBaseline from '@mui/material/CssBaseline';
import {Loading} from "../../Loading";
import KidsList from "../KidsList";
import {SeriesSelect} from "../SeriesSelect";
import {useWidth} from "../../../lib/hooks";
import {useTranslation} from "react-i18next";
import {TransitionGroup} from "react-transition-group";
import {ClausesList} from "../ClausesList";
import {Circle, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import ShirtSelect from "../ShirtSelect";
import Insurance from "../Insurance";
import {AddToCart} from "../../Order/AddToCart";
import {scrollToElement} from "../../../lib/utils";
import {TeamSelect} from "../TeamSelect";
import {useSelector} from "react-redux";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import {NumberOfParticipantsSelect} from "../NumberOfParticipantsSelect";
import {Participants} from "../Participants";
import cloneDeep from "clone-deep";
import {client} from "../../../lib/Client";
import AlreadyInCart from "../AlreadyInCart";
// import {StarterPackSelect} from "../StarterPackSelect";
// import {TeamSelect} from "../TeamSelect";
// import {ClausesList} from "../ClausesList";

const defaults = {
    kid: null,
    series: null,
    clauses: null,
    shirt_size: null,
    insurance: null,
    team: null,
    participants: null,
    number_of_participants: null

}

const buildSteps = (race) => {
    const steps = []
    if (race.multiple_participants) {
        steps.push("number_of_participants");
        steps.push("series");
        steps.push("participants");
        //steps.push("participants");
    } else {
        if (race.enable_children_subscriptions) {
            steps.push("kid");
        }
        steps.push("series");
    }


    if (race.enable_teams) {
        steps.push("team");
    }
    // starter_pack
    if (race.enable_additional_shirt) {
        steps.push("shirt_size");
    }
    if (race.is_insurance) {
        steps.push("insurance");
    }
    steps.push("clauses");

    return steps
}


export const SignUpStepper = ({race}) => {
    const {loading, cart} = useSelector((state) => state.cart);
    const [alreadyInCart, setAlreadyInCart] = useState(false);
    useEffect(() => {
        if (race.enable_multiple_subscriptions) {
            return;
        }
        if (loading) {
            return;
        }
        for (const item of cart.items) {
            if (item.discriminator === "SubscriptionOrderItem" && item.series.event.id === race.id) {
                setAlreadyInCart(true);
                return;
            }
        }

    }, [loading, cart, race]);

    const wrapperRef = useRef();
    const containerRef = useRef();
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const width = useWidth();
    // options


    // selected values
    const [data, setData] = React.useState(defaults);
    const [kid, setKid] = useState(null);
    const [numberOfParticipants, setNumberOfParticipants] = useState(null);
    const [series, setSeries] = useState(null);
    const [shirtSize, setShirtSize] = useState(null);
    const [starterPack, setStarterPack] = useState(null);
    const [team, setTeam] = useState(null);
    const [clauses, setClauses] = useState([]);
    const [insurance, setInsurance] = useState(null);

    const steps = buildSteps(race);


    const handleAdd = () => {
        setData(defaults);
        setKid(null);
        setSeries(null);
        setShirtSize(null);
        setStarterPack(null);
        setTeam(null);
        setClauses([]);
        setInsurance(null);
        setActiveStep(0);
    }
    const handleNext = () => {
        setActiveStep(activeStep + 1);
        scrollToElement(wrapperRef.current);
    }
    const handleBack = () => {
        setActiveStep(activeStep - 1);
        scrollToElement(wrapperRef.current);
    }

    const updateData = (key, value) => {
        let newData = cloneDeep(data);
        newData[key] = value;
        setData(newData);
    }
    const updateDataMulti = (kv) => {
        let newData = cloneDeep(data);
        for (const [key, value] of Object.entries(kv)) {
            newData[key] = value;
        }
        setData(newData);
    }

    const handleSelectKid = (kid) => {
        setKid(kid);
        updateDataMulti({
            'kid': kid.id,
            'series': null
        });
        handleNext(); // race
    }

    const handleSelectSeries = (series) => {
        setSeries(series);
        updateData('series', series.id);
        handleNext();
    }

    const handleSelectStarterPack = (starterPack) => {
        setStarterPack(starterPack);
        handleNext();
    }

    const handleSelectTeam = (team) => {
        setTeam(team);
        handleNext();
    }

    const handleSelectShirtSize = (newShirtSize) => {
        if (shirtSize != newShirtSize) {
            setShirtSize(newShirtSize);
            updateData('shirt_size', newShirtSize);
            if (newShirtSize !== null) {
                handleNext();
            }
        }
    }
    const handleChangeInsurance = (newInsurance) => {
        if (insurance !== newInsurance) {
            setInsurance(newInsurance);
            updateData('insurance', newInsurance);
            if (newInsurance === false) {
                handleNext();
            }
        }
    }
    const handleChangeTeam = (newTeam) => {
        if (team !== newTeam) {
            setTeam(newTeam);
            updateData('team', newTeam);
            if (newTeam === false) {
                handleNext();
            }
        }
    }
    const handleSelectClauses = (clauses) => {
        setClauses(clauses);
        updateData('clauses', clauses);
    }
    const handleSelectNumberOfParticpants = (v) => {
        setNumberOfParticipants(v);
        updateData('number_of_participants', v);
        handleNext();
    }
    const handleChangeParticipants = (v) => {
        updateData('participants', v);
    }

    const renderActiveStep = () => {
        const currentStep = steps[activeStep]
        if (currentStep == 'participants') {
            return <Participants
                value={data.participants}
                numberOfParticipants={numberOfParticipants}
                race={race}
                onChange={handleChangeParticipants}
            ></Participants>
        }
        if (currentStep == 'number_of_participants') {
            return <NumberOfParticipantsSelect value={numberOfParticipants} race={race}
                                               onSelect={handleSelectNumberOfParticpants}></NumberOfParticipantsSelect>
        }
        if (currentStep == 'kid') {
            return <KidsList value={data.kid} race={race} onSelect={handleSelectKid}></KidsList>
        }
        if (currentStep == 'series') {
            return <SeriesSelect value={data.series} numberOfParticipants={numberOfParticipants} race={race} kid={kid}
                                 onSelect={handleSelectSeries}></SeriesSelect>
        }
        if (currentStep == 'clauses') {
            return <ClausesList value={data.clauses} race={race} kid={kid} onSelect={handleSelectClauses}></ClausesList>
        }
        if (currentStep == 'shirt_size') {
            return <ShirtSelect value={data.shirt_size} onSelect={handleSelectShirtSize}></ShirtSelect>
        }
        if (currentStep == 'insurance') {
            return <Insurance value={data.insurance} race={race} series={series} kid={kid}
                              onChange={handleChangeInsurance}></Insurance>
        }
        if (currentStep == 'team') {
            return <TeamSelect value={data.team} race={race} onChange={handleChangeTeam}></TeamSelect>
        }
        return <>TODO {currentStep}</>
    }

    const validActiveStep = () => {
        const currentStep = steps[activeStep]
        return data[currentStep] !== null;
    }

    const dataForCart = () => {
        const preparedData = {
            race: race.id,
            kid: data.kid,
            series: data.series,
            shirt_size: data.shirt_size,
            insurance: [],
            participants: {},
        }
        if (data.insurance !== false && data.insurance !== null) {
            preparedData.insurance = data.insurance.race;
            preparedData.insurance_training = data.insurance.training;
            preparedData.insurance_agreement_marketing = data.insurance.agreementMarketing;
        }
        if (data.clauses) {
            for (let clause of data.clauses) {
                preparedData[`clause${clause}`] = 1;
            }
        }
        if (data.shirt_size === false) {
            delete preparedData.shirt_size;
        }
        if (data.participants) {
            for (let i = 2; i <= numberOfParticipants; i++) {
                preparedData.participants[`participant_${i}`] = {
                    participant_type: data.participants[`participant_${i}_type`],
                    user: data.participants[`participant_${i}_user`],
                    kid: data.participants[`participant_${i}_kid`],
                }
            }
        }
        return preparedData;
    }

    if (activeStep === 0 && alreadyInCart) {
        return <AlreadyInCart race={race}></AlreadyInCart>

    }

    if (!(['kids', 'junior', 'family'].includes(race.event_type.external_id ))) {
        return <></>
    }

    return <Box sx={{width: '100%', mb: 5}} ref={wrapperRef}>

        {!['xs', 's'].includes(width) && <Stepper activeStep={activeStep}>
            {steps.map((step) => <Step key={step}><StepLabel>{t(`steps.${step}Title`)}</StepLabel></Step>)}
        </Stepper> }
        {['xs', 's'].includes(width) && <Paper
            square
            elevation={0}
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: 50,
                pl: 2,
                bgcolor: 'background.default',
            }}
        >
            <StepLabel about="asd" icon={2} title={"lorem"} StepIconProps={{active:true}}>
                <Typography>{t(`steps.${steps[activeStep]}Title`)}</Typography>
            </StepLabel>
        </Paper>}
        <React.Fragment>
            <Box sx={{mt: 2, mb: 1}} ref={containerRef}>
                <TransitionGroup>
                    <Slide container={containerRef.current} direction={"left"} key={activeStep}>
                        <Paper sx={{p: 2}}>
                            {renderActiveStep()}
                        </Paper>
                    </Slide>
                </TransitionGroup>
            </Box>

            <MobileStepper
                variant="progress"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    activeStep === steps.length - 1
                        ? <AddToCart
                            onAdd={handleAdd}
                            disabled={!validActiveStep()}
                            data={[{type: "start", ...dataForCart()}]}
                        ></AddToCart>
                        : <Button
                            size="small"
                            onClick={handleNext}
                            variant="contained"
                            disabled={!validActiveStep()}
                        >
                            {t('next')}
                            <KeyboardArrowRight/>
                        </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}>
                        <KeyboardArrowLeft/>
                        {t('back')}
                    </Button>
                }
            />
        </React.Fragment>
    </Box>

}
