import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense, useState} from "react";
import {
    Box,
    Button,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider
} from "@mui/material";
import {useTranslation} from "react-i18next";
import TuneIcon from '@mui/icons-material/Tune';
import FilterListIcon from '@mui/icons-material/FilterList';
import {theme} from "../../../lib/theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


export const StoreFilters = () => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const toggle = (open) => () => {
        setOpen(open)
    }
    const items = (list) => {
        if (list === undefined) {
            list = document.querySelector("#store_sidebar>ul");
        }
        let categories = Array.from(list.childNodes);
        categories = categories.filter((node) => node.nodeName === "LI");



        return <>{categories.map( (node, index) => {
            const children = Array.from(node.childNodes);
            const item = children.filter((node) => node.nodeName === "A")[0]
            const uls = children.filter((node) => node.nodeName === "UL");
            const ul = uls.length > 0 ? uls[0] : undefined;

            return <><ListItem disablePadding key={index}>
                <ListItemButton href={item.getAttribute("href")} component="a">
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                </ListItemButton>

            </ListItem>
            {ul ? <List component="div" sx={{pl: 4}}>{items(ul)}</List> : ''}
                </>
        })}</>
    }
    return <>
        <Button variant="outlined" startIcon={<TuneIcon/>} onClick={toggle(true)}>Filtry</Button>
        <Drawer anchor="left" open={open} onClose={toggle(false)}>
            <Box
                sx={{ width: 250, marginTop: 4 }}
                role="presentation"
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <FilterListIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Kategorie" />
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                    {items()}
                </List>
            </Box>
        </Drawer>
    </>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <StoreFilters {...props}/>
        </Suspense>
    </ThemeProvider>
}

class StoreFiltersElements extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-store-filters', StoreFiltersElements)

export default StoreFiltersElements