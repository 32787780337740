import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {client} from "../../lib/Client";

export const fetchKids = createAsyncThunk('kids/fetchKids', async () => {
    const response = await client.userKids();
    return response.data
})

const kidsSlice = createSlice({
    name: 'users',
    initialState: {
        kids: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchKids.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchKids.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.kids = action.payload
            })
            .addCase(fetchKids.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export default kidsSlice.reducer

export const selectKidById = (state, kidId) =>
    state.kids.kids.find(kid => kid.id === kidId)

