import {formatMoney} from "../../../lib/utils";
import React from "react";

function InsuranceItem(props) {
    function handleChange() {
        props.toggle(props.id);
    }
    return <div className="insurance-item">
        {!props.showName &&
        <label htmlFor={"insurance_" + props.id}>
            <p><small className="insurance-description">{props.description}</small></p>
        </label> }
        <div className={"form-check"}>
            <input type={"checkbox"} name={props.input_name + "[]"} value={props.id} id={"insurance_" + props.id} checked={props.chosen.includes(props.id)} onChange={handleChange}/>
            <label htmlFor={"insurance_" + props.id}>
                <strong>
                    {props.showName && <span className={"insurance-name"}>{props.name}</span>} <span>{formatMoney({amount: props.price*100, currency: {code: "PLN"}})}</span>
                </strong>
            </label>
        </div>
        {props.showName &&
        <label htmlFor={"insurance_" + props.id}>
            <p><small className="insurance-description">{props.description}</small></p>
        </label> }
    </div>
}

export default InsuranceItem;