import {alpha, Button, ButtonGroup, FormControl, FormHelperText, InputBase, styled} from "@mui/material";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Boy, Done, Girl, NotInterested} from "@mui/icons-material";

const KidGenderSelect = ({value, onChange, name, error, helperText}) => {
    const {t} = useTranslation();
    const femaleRef = useRef();
    const maleRef = useRef();

    const handleClick = (newValue) => (event) => {
        if (onChange) {
            event.target = newValue === "female" ? femaleRef.current : maleRef.current;
            onChange(event, newValue);
        }
    }

    return (
        <React.Fragment>
            <FormControl error={error}>
                <ButtonGroup
                    disableElevation
                    size="large"
                    fullWidth={true}
                >
                    <Button
                        ref={femaleRef}
                        color="error"
                        name={name}
                        value={"female"}
                        onClick={handleClick("female")}
                        variant={value === "female" ? "contained" : "outlined"}
                        startIcon={<Girl/>}
                    >{t('girl')}</Button>
                    <Button
                        ref={maleRef}
                        color="success"
                        name={name}
                        value={"male"}
                        onClick={handleClick("male")}
                        variant={value === "male" ? "contained" : "outlined"}
                        endIcon={<Boy/>}
                    >{t('boy')}</Button>
                </ButtonGroup>
                {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </FormControl>
        </React.Fragment>
    );
}

export default KidGenderSelect;