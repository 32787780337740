import React from "react";
import Badge from '@mui/material/Badge';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import {navigation} from "../../lib/user";


const HeaderButtons = () => {
    const {loading, cart}  = useSelector((state) => state.cart)
    const {authenticated, user} = useSelector((state) => state.user)
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    let count = 0;
    if (!loading) {
        count = cart.items.length;
        count = cart.items.reduce((accumulator, item) => accumulator + item.quantity, 0);
    }

    let userAvatar = <a href={t("routes.user")}><i className="icon-rmg-user"/></a>
    if (authenticated) {
        const size = 25
        userAvatar = <Tooltip title={t("userNav.tooltip")}>
            <IconButton color="primary"
            onClick={handleClick}
            size="small"
            sx={{ ml: "1px" }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
        >
                <Avatar sx={{ bgcolor: "primary.main", width: size, height: size, fontSize: 15}}>{user.first_name[0]}</Avatar>
            </IconButton></Tooltip>

    }

    return <>
            <a href={t("routes.search")} id="header-search-toggle"><i className="icon-search"></i>
            </a><a href={t("routes.cart")}>
        <Badge badgeContent={count} color="primary" sx={{verticalAlign: "initial"}}>
            <i className="icon-rmg-cart" style={{fontSize: "22px"}}></i>
        </Badge>
    </a>{userAvatar}

        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {navigation.map((item, index) => {
                const props = {}
                if ("path" in item) {
                    props["href"] = t(item.path);
                    props["component"] = "a"
                } else if ("onClick" in item) {
                    props["onClick"] = item.onClick;
                }

                return <MenuItem key={index} {...props}>
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon> {t(item.label)}
                </MenuItem>
            })
            }

        </Menu>

    </>
}

export default HeaderButtons;

