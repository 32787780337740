import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {blue, purple, } from '@mui/material/colors';
import FacebookIcon from '@mui/icons-material/Facebook';

const ColorButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#303f9f",
    '&:hover': {
        backgroundColor: "#1a237e",
    },
}));

export default function FacebookShareButton({...props}) {
    return (
        <ColorButton variant="contained" startIcon={<FacebookIcon/>} {...props}>
            {props.children}
        </ColorButton>

    );
}