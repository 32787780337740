import React, {createElement, Suspense, useEffect, useState} from 'react';
import {Loading} from "../Loading";
import {formatMoney} from "../../lib/utils";
import {FormControl, InputLabel, MenuItem, Select, ThemeProvider} from "@mui/material";
import {render, unmountComponentAtNode} from "react-dom";
import {client} from "../../lib/Client";
import {AddToCart} from "../Order/AddToCart";
import InsuranceProduct from "../Insurance/InsuranceProduct";
import {theme} from "../../lib/theme";
import {Provider, useDispatch} from "react-redux";
import {addMessage} from "../../features/alerts/alertsSlice";
import store from "../../store";



const InsuranceLandingPage = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [startsLoading, setStartsLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [itemsTraining, setItemsTraining] = useState([]);

    const [chosen, setChosen] = useState([]);
    const [chosenTraining, setChosenTraining] = useState([]);

    // starts
    const [starts, setStarts] = useState([]);
    const [start, setStart] = useState('');

    const [answers, setAnswers] = useState([]);
    const validStarts = starts.filter((start) => (start.status === 1 || start.status === 4) && start.currency === "PLN");


    useEffect(() => {
        setLoading(true);

        const first = client.userStarts().then(response => {
            setStarts(response.data);
        }, error => {
            console.log(error);
        });
        const second = client.insuranceTrainingQuotes().then(response => {
            setItemsTraining(response.data.quotes);
        }, error => {
           const errors = error.response.data.errors;
           for (error of errors) {
            dispatch(addMessage({message: error, severity: "error"}));
           }
        });
        Promise.all([first, second]).then(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        setStartsLoading(true);
        if (start === '') {
            setItems([]);
            setStartsLoading(false);
            return;
        }

        client.insuranceStartQuotes(start).then(response => {
            setItems(response.data.quotes);
        }, error => {
            setStartsLoading(false);
            setItems([]);
        }).then(() => {
            setStartsLoading(false);
        })
    }, [start]);
    useEffect(() => {

        if(validStarts.length !== 0 && start === '') {
            setStart(validStarts[0].id);
        }
    }, [starts]);

    function extracted(items) {
        const itemsByProduct = {};
        for (let i in items) {
            let item = items[i]
            if (!(item['product_id'] in itemsByProduct)) {
                itemsByProduct[item['product_id']] = {
                    "id": item['product_id'],
                    "name": item['product_name'],
                    "items": []
                }
            }
            itemsByProduct[item['product_id']]["items"].push(item);
        }
        return Object.values(itemsByProduct);
    }

    const itemsByProduct = extracted(items);
    const itemsTrainingByProduct = extracted(itemsTraining).filter((item) => {
        if(item.id === 1 && answers.includes("reh")) {
            return true;
        }
        if(item.id === 2 && answers.includes("nnw")) {
            return true;
        }
    }).reverse();

    const toggleChosen = (value) => (event) => {
        if (chosen.includes(value)) {
            const new_chosen = chosen.filter(function (ele) {
                return ele !== value;
            });
            setChosen(new_chosen);
        } else {
            const new_chosen = chosen.concat([value]);
            setChosen(new_chosen);
        }
    }

    function toggleChosenTraining(value) {
        if (chosenTraining.includes(value)) {
            const new_chosen = chosenTraining.filter(function (ele) {
                return ele !== value;
            });
            setChosenTraining(new_chosen);
        } else {
            let new_chosen = chosenTraining
            for (let i in itemsTrainingByProduct) {
                let product = itemsTrainingByProduct[i];
                let item_ids = product.items.map((item) => item.id);
                if (item_ids.includes(value)) {
                    new_chosen = chosenTraining.filter(function (ele) {
                        return !item_ids.includes(ele);
                    });
                }
            }
            new_chosen = new_chosen.concat([value]);
            setChosenTraining(new_chosen);
        }
    }

    function toggleAnswer(value) {
        if (answers.includes(value)) {
            const new_chosen = answers.filter(function (ele) {
                return ele !== value;
            });
            setAnswers(new_chosen);
        } else {
            const new_chosen = answers.concat([value]);
            setAnswers(new_chosen);
        }
    }

    const handleChange = (event) => {
        setStart(event.target.value);
    }

    let resignationItem = null;
    for(let i in itemsByProduct) {
        if (itemsByProduct[i].id === 6) {
            resignationItem = itemsByProduct[i].items[0];
            break;
        }
    }

    if (loading) {
        return <Loading/>
    }

    const startName = (start) => {
        const fullDate = new Date(start.series.date + "T" + start.series.time);
        const options = { day: 'numeric', month: 'numeric', year: "numeric" };
        const options_time = { hour: '2-digit', minute: '2-digit' };
        let name = start.series.event.name + " " + start.series.event.event.place;
        name = name.replace(/runmageddon/gi, "");
        return fullDate.toLocaleDateString("pl-PL", options) + " " + fullDate.toLocaleTimeString("pl-PL", options_time) + " " + name;
    }

    const addToCartData = [];
    if (chosenTraining.length > 0) {
        addToCartData.push({"type":"insurance_training", "insurance_training": chosenTraining});
    }
    if (chosen.length > 0) {
        addToCartData.push({"type":"insurance", "insurance": chosen, "start_id": start});
    }


    return (
        <div>
            <div className="insurance-questions">
                <InsuranceQuestion chosen={answers} id="nnw" description="Czy interesuje Cię ubezpieczenie następstw nieszczęśliwego wypadku, które mogą się zdarzyć podczas treningu?" name="NNW" toggle={toggleAnswer}/>
                <InsuranceQuestion chosen={answers} id="reh" description="Czy interesuje Cię ubezpieczenie, w ramach którego będziesz mógł uzyskać zwrot kosztów rehabilitacji, w przypadku doznania urazu ciała lub
złamania kości podczas treningu?" name="REHABILITACJA" toggle={toggleAnswer}/>
                <InsuranceQuestion chosen={answers} id="res" description="Czy interesuje Cię ubezpieczenie zwrotu kosztów biletu w przypadku braku możliwości uczestnictwa w zawodach?" name="REZYGNACJA" toggle={toggleAnswer}/>
            </div>
            {answers.length !== 0 && <React.Fragment>
                <div className="insurance-subcaption">Zgodnie z analizą Twoich potrzeb przedstawiamy następujące produkty.</div>
                <div className="insurance-caption">Wybierz, zaznacz i kup</div>
                <div className="insurance-products">
                    {itemsTrainingByProduct.map((product => <InsuranceProduct key={product.id} {...product}
                                                                              input_name="insurance_training"
                                                                              chosen={chosenTraining}
                                                                              toggle={toggleChosenTraining}/>))}
                    {answers.includes("res") && <React.Fragment>
                        <div className="insurance-product">
                            <div className="insurance-product-name">REZYGNACJA</div>
                            {validStarts.length === 0 && <div>Nie masz żadnych nadchodzących startów</div>}
                            {validStarts.length > 0 && <React.Fragment>
                                <div>
                                    <FormControl fullWidth variant="standard" sx={{margin: "2rem 0"}}>
                                        <InputLabel id="select-start-label">Wybierz start który chcesz ubezpieczyć</InputLabel>
                                        <Select
                                            labelId="select-start-label"
                                            value={start}
                                            label="Wybierz"
                                            onChange={handleChange}
                                        >
                                            {validStarts.map((start => <MenuItem
                                                value={start.id}
                                                key={start.id}>{startName(start)}</MenuItem>))}
                                        </Select>
                                    </FormControl>

                                    <div className="insurance-item">
                                        <label htmlFor={"insurance_6"}>
                                            <p><small className="insurance-description">Brak udziału w wydarzeniu sportowym z powodów losowych, m.in. poważne zachorowanie, śmierć osoby bliskiej, utrata pracy, pożar mieszkania, wypadek lub kolizja drogowa. Zwrot kosztów biletu, nie więcej niż 1.500 zł</small></p>
                                        </label>
                                        {resignationItem &&
                                        <div className={"form-check"}>
                                            <input type={"checkbox"} name="insurance[]" value={resignationItem.id} id={"insurance_" + resignationItem.id}
                                                   checked={chosen.includes(resignationItem.id)} onChange={toggleChosen(resignationItem.id)}/>
                                            <label htmlFor={"insurance_" + resignationItem.id}>
                                                <strong>
                                                    <span>{formatMoney({
                                                        amount: resignationItem.price * 100,
                                                        currency: {code: "PLN"}
                                                    })}</span>
                                                </strong>
                                            </label>
                                        </div> }
                                    </div>
                                </div>
                            </React.Fragment>}
                        </div>

                    </React.Fragment> }
                </div>
                <div style={{"textAlign": "center"}}>
                    <AddToCart disabled={addToCartData.length === 0} size="large"
                               label="Dodaj do koszyka" data={addToCartData}/>
                </div>

                <div>Partner ubezpieczeniowy UNIQA S.A.</div>
                <div>Agentem ubezpieczeniowym jest Ubezpieczenia dla Aktywnych Sp. z o.o. (UDAO) agent UNIQA S.A.
                </div>
            </React.Fragment>}

        </div>
    );
}

function InsuranceQuestion(props) {
    function handleChange(e) {
        props.toggle(props.id);
    }
    return <div className="insurance-question">
        <div className="insurance-question-icon">
            <label htmlFor={"insurance_question_" + props.id}>
                <img src={"/images/insurance/" + props.id + ".png"} alt={props.name}/>
            </label>
        </div>
        <div className="insurance-question-description">
            <label htmlFor={"insurance_question_" + props.id}>
                <p>{props.description} <span>({props.name})</span></p>
            </label>
        </div>
        <div className="insurance-question-checkbox">
            <div className={"form-check"}>
                <input type={"checkbox"} name={"insurance_question[]"} value={props.id} id={"insurance_question_" + props.id} checked={props.chosen.includes(props.id)} onChange={handleChange}/>
                <label htmlFor={"insurance_question_" + props.id}> </label>
            </div>
        </div>
    </div>
}

const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <Provider store={store}>
                <InsuranceLandingPage {...props}/>
            </Provider>
        </Suspense>
    </ThemeProvider>
}


class InsuranceLandingPageHTMLElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value]);
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-insurance-landing-page', InsuranceLandingPageHTMLElement)

export default InsuranceLandingPageHTMLElement