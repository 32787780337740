import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next"; // This only needs to be imported once in your app
import DownloadIcon from '@mui/icons-material/Download';
import FacebookShareButton from "../../components/Common/FacebookShareButton";

const EventGalleryPhoto = () => {
    const {t} = useTranslation()
    const {photoId} = useParams()
    const [images, race, gallery] = useOutletContext();
    const navigate = useNavigate();

    if (images.length === 0) {
        return <></>
    }

    const basePath = `${t('routes.gallery')}/${race.slug}/${gallery.slug}`
    const photoIndex = images.findIndex(image => image.id.toString() === photoId.toString());

    return <Lightbox
            mainSrc={images[photoIndex].image}
            nextSrc={images[(photoIndex + 1) % images.length].image}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
            onCloseRequest={() => navigate(basePath) }
            onMovePrevRequest={() => {
                const prevIndex = (photoIndex + images.length - 1) % images.length

                navigate(`${basePath}/${images[prevIndex].id}`);
            }}
            onMoveNextRequest={() => {
                const nextIndex = (photoIndex + 1) % images.length
                navigate(`${basePath}/${images[nextIndex].id}`);
            }}
            toolbarButtons={[
                <Button sx={{mr: 2}} key="1" variant="contained" href={images[photoIndex].full} size="small" startIcon={<DownloadIcon/>}>{t('download')}</Button>,
                <FacebookShareButton key="2" size="small">{t('share')}</FacebookShareButton>
            ]}
            enableZoom={false}
            reactModalStyle={{overlay: {zIndex: 1200}}}
    />
}

export default EventGalleryPhoto;