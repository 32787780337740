import * as yup from 'yup'
import i18n from "../i18n";

yup.addMethod(yup.string, "pesel", function (anyArgsYouNeed) {
    return this
        .matches('^[0-9]+$', i18n.t("Only digits allowed"))
        .length(11, i18n.t("PESEL consists of exactly 11 digits"))
        .test("test-gender", function (value, context) {
            const {path, createError} = this;
            const {gender} = this.parent;

            if (value && value.length === 11) {
                if (gender === "female" && parseInt(value[9]) % 2 === 1) {
                    return createError({
                        message: i18n.t("Selected gender does not align with PESEL"),
                        path: path
                    });
                }
                if (gender === "male" && parseInt(value[9]) % 2 === 0) {
                    return createError({
                        message: i18n.t("Selected gender does not align with PESEL"),
                        path: path
                    });
                }
            }
            return true
        }).test("test-birth-date", function (value) {
            const {path, createError} = this;
            const {birth_date} = this.parent;
            if (value && value.length === 11 && birth_date) {

                let year = parseInt(value.substring(0,2));
                let month = parseInt(value.substring(2,4));
                const day = parseInt(value.substring(4,6));

                if (month < 20) {
                    year+= 1900
                }
                else if (month > 20 && month < 40) {
                    year += 2000;
                    month -= 20;
                }
                else if (month > 40 && month < 60) {
                    year += 2100;
                    month -= 40;
                }
                else if (month > 60 && month < 80) {
                    year += 2200;
                    month -= 60;
                }
                const date = new Date();
                date.setUTCFullYear(year, month - 1, day, 0);
                date.setUTCHours(0,0,0,0);
                if(date.toISOString().substring(0,10) !== birth_date.toISOString().substring(0,10)) {
                    return createError({
                        message: i18n.t("Birth date does not align with PESEL"),
                        path: path
                    });
                }
                return true;
            }
            return false;
        }).test("test-checksum", function (value) {
            const {path, createError} = this;
            const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            if (value && value.length === 11) {

                const sum = weights.reduce((previousValue, currentValue, currentIndex) => {
                    return previousValue + currentValue * parseInt(value[currentIndex]);
                }, 0);
                const checksum = sum % 10 === 0 ? 0 : 10 - (sum % 10);
                if (checksum !== parseInt(value[10])) {
                    return createError({
                        message: i18n.t("Invalid checksum"),
                        path: path
                    });
                }
                return true;
            }
            return false;
        });
});

yup.addMethod(yup.string, 'sequence', function (funcList) {
    return this.test(async (value, context) => {
        try {
            for (const func of funcList) {
                await func().validate(value, context);
            }
        } catch ({ message }) {
            return context.createError({ message });
        }
        return true;
    });
});