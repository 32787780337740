import { createSlice } from '@reduxjs/toolkit'

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        alerts: window.app_alerts
    },
    reducers: {
        addMessage: (state, action) => {
            state.alerts.push({
                message: action.payload.message,
                severity: action.payload.severity
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { addMessage } = alertsSlice.actions

export default alertsSlice.reducer
