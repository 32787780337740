import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DateField} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import * as locales from '@mui/x-date-pickers/locales';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {pl} from "dayjs/locale/pl"
import {en} from "dayjs/locale/en"
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';


const DateSelect = ({value, name, onChange, ...props}) => {
    const {t, i18n} = useTranslation();
    const [internalValue, setInternalValue] = useState(value);

    const language = i18n.language;
    const locale = language === "pl" ? 'plPL' : 'enUS';

    const text = locales[locale].components.MuiLocalizationProvider.defaultProps.localeText;


    const handleChange = (newValue, context) => {
        setInternalValue(newValue);
        if (context.validationError === null) {
            const event = new Event("change");
            const target = {
                type: "text",
                name: name,
                id: undefined,
                value: newValue,
                checked: undefined,
                outerHTML: undefined,
                options: undefined,
                multiple: undefined
            }
            Object.defineProperty(event, 'target', {writable: false, value: target});
            onChange(event, newValue);
        }
    }

    return (
        <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={language}
            localeText={text}
        >
            <DateField
                value={internalValue}
                onChange={handleChange}
                formatDensity="spacious"
                clearable={true}
                timezone="UTC"
                {...props}
            />

        </LocalizationProvider>

    );
}
export default DateSelect