import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, IconButton,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from '@mui/icons-material/Close';
import {post} from "../../lib/utils";
import LoginForm from "../Forms/LoginForm";
import KidCreateForm from "../Forms/KidCreateForm";


const KidCreateDialog = ({open, handleClose, onAdd}) => {
    const {t} = useTranslation();

    const handleSuccess = (data) => {
        if (onAdd) {
            onAdd(data);
        }
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle>
                {t('Create kid profile')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <KidCreateForm onSuccess={handleSuccess}/>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

export default KidCreateDialog