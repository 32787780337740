import {Box, ImageList, ImageListItem, Tab, Tabs} from "@mui/material";
import {useWidth} from "../../lib/hooks";
import React, {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import UpcomingEvent from "../../components/Common/UpcomingEvent";
import {Link, Outlet, useLocation, useMatch, useMatches, useNavigate, useParams} from "react-router-dom";
import {Loading} from "../../components/Loading";
import {client} from "../../lib/Client";
import {useTranslation} from "react-i18next";
import EventGalleryBanner from "./banner";
import {wildcardSearch} from "../../lib/utils";

const responsiveColumns = {
    "xs": 2,
    "s": 3,
    "sm": 4,
    "md": 5,
    "lg": 6,
    "xl": 7
}


const EventGallery = () => {
    const width = useWidth();
    const cols = responsiveColumns[width];
    const [visible, setVisible] = useState(cols * 3);
    const count = 12;

    const {eventSlug, gallerySlug} = useParams()
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [race, setRace] = useState({})
    const [galleries, setGalleries] = useState([]);
    const [banners, setBanners] = useState([]);
    const [gallery, setGallery] = useState(-1);

    const [images, setImages] = useState([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    let location = useLocation();
    const [visibleBanners, setVisibleBanners] = useState([]);

    const fetchRace = () => {
        setLoading(true);
        return client.race(eventSlug).then((response) => {
            setLoading(false)
            setRace(response.data);
            return fetchGalleries();
        }).catch((error) => {
            console.log('There was an error fetching race', error)
        })
    }

    const fetchGalleries = () => {
        if (typeof race.id === "undefined") {
            setGalleries([]);
            return;
        }
        setLoading(true);
        return client.raceGalleries(race.id).then((response) => {
            setLoading(false)
            setGalleries(response.data);

        }).catch((error) => {
            console.log('There was an error fetching race', error)
        });
    }

    const fetchImages = () => {
        setImagesLoading(true);
        setImages([]);
        return client.raceGalleryPhotos(race.id, galleries[gallery].id).then((response) => {
            setImagesLoading(false);
            setVisible(cols * 3);
            setImages(response.data.photos);

        }).catch((error) => {
            console.log('There was an error fetching race', error)
        });
    }

    const fetchBanners = () => {
        return client.banners().then((response) => {

            setBanners(response.data);

        }).catch((error) => {
            console.log('There was an error fetching banners', error)
        });
    }

    useEffect(() => { fetchRace(); }, [eventSlug]);
    useEffect(() => { fetchGalleries(); }, [race]);

    useEffect(() => {
        const galIndex = galleries.findIndex((item) => item.slug === gallerySlug);
        setGallery(galIndex);
    }, [galleries, gallerySlug])

    useEffect(() => {
        if (gallery === -1) {
            setImages([]);
        } else {
            fetchImages();
        }

    }, [gallery])


    useEffect(() => {
        if (!inView) {
            return;
        }
        setVisible(visible + 5 * cols);
    }, [inView]);

    useEffect(() => {
        fetchBanners();
    }, []);

    useEffect(() => {
        const newBanners = [];
        for (const banner of banners) {
            if (!banner.path) {
                newBanners.push(banner);
            } else {
                const paths = banner.path.split("\n");
                for (const path of paths) {
                    if (wildcardSearch(path, location.pathname)) {
                        newBanners.push(banner);
                        break;
                    }
                }
            }
        }
        setVisibleBanners(newBanners);
    }, [race, banners]);

    if (loading || gallery === -1) {
        return <Loading/>
    }

    const itemData = images.slice(0, visible);

    return <>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={gallery}>
                {galleries.map((gallery) => {
                    const url = `${t('routes.gallery')}/${race.slug}/${gallery.slug}`
                    return <Tab key={gallery.id} label={gallery.name} onClick={() => navigate(url)}/>
                }) }
            </Tabs>
        </Box>
        { imagesLoading && <Box sx={{m: 3}}><Loading/></Box> }
        { !imagesLoading && <>
            <Outlet context={[images, race, galleries[gallery]]}/>
            <ImageList sx={{overflowY: "visible"}} cols={cols} gap={4}>
                {itemData.map((item, index) => (
                    <React.Fragment key={item.id}>
                        {visibleBanners.length > 0 && index > 0 && index % (cols * count) === 0 &&
                        <div style={{gridColumnStart: 1, gridColumnEnd: cols + 1}}>
                            <EventGalleryBanner banner={visibleBanners[(index / (cols * count) - 1) % visibleBanners.length]}/>
                        </div>}
                        <ImageListItem sx={{"&:hover img":{"opacity":0.7}}}>
                            <Link to={ `${item.id}` } preventScrollReset={true} >
                                <img src={item.src} loading="lazy" alt=""/>
                            </Link>
                        </ImageListItem>
                    </React.Fragment>
                ))}
            </ImageList>
            <div ref={ref}/>
        </> }
    </>
}

export default EventGallery;
