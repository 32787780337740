import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {setLocale} from "yup";
import * as locales from "yup-locales";

i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        detection: {
            order: ['htmlTag']
        },
        fallbackLng: 'pl',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true
        },
        backend: {
            queryStringParams: { v: window.VERSION },
        }
    });

i18n.on('languageChanged', () => {
    if (i18n.language in locales) {
        setLocale(locales[i18n.language]);
    } else {
        setLocale({})
    }
});

export default i18n;
