import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import {Avatar, Box, Button, ListItemAvatar, Stack} from "@mui/material";
import {Girl, Boy, Add} from "@mui/icons-material";
import {blue, pink} from '@mui/material/colors';
import {client} from "../../../lib/Client";
import {calculateAgeAtDate} from "../../../lib/utils";
import {Loading} from "../../Loading";
import KidCreateDialog from "../../KidCreateDialog";

export default function KidsList({value, race, onSelect}) {
    const [loading, setLoading] = useState(false)
    const [kids, setKids] = useState([]);

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        fetchKids();
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    const fetchKids = () => {
        setLoading(true);
        client.userKids().then((response) => {
            setKids(response.data);
            setLoading(false);
        }).catch((error) => {
            console.error('There was an error fetching kids', error)
        });
    }

    useEffect(() => {
        setKids([]);

        fetchKids();
    }, []);


    const handleClick = (value) => () => {
        onSelect(value);
    };
    if (loading) {
        return <>
            <Loading/>
        </>
    }
    return (
        <React.Fragment>
            <Stack spacing={2}>
                <List variant="grid" dense={true}>
                    {kids.map((kid) => {
                        const labelId = `checkbox-list-label-${kid.id}`;
                        const age = calculateAgeAtDate(kid.birth_date, race.date);
                        return (
                            <ListItem
                                key={kid.id}
                                disablePadding
                                sx={{flex: '1 1 0%'}}
                            >
                                <ListItemButton selected={value === kid.id} onClick={handleClick(kid)} dense>
                                    <ListItemAvatar>
                                        {kid.gender === 'female' &&
                                            <Avatar sx={{bgcolor: "female.main"}}>
                                                <Girl></Girl>
                                            </Avatar>
                                        }
                                        {kid.gender === 'male' &&
                                            <Avatar sx={{bgcolor: "male.main"}}>
                                                <Boy></Boy>
                                            </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText id={labelId}
                                                  primary={`${kid.first_name} ${kid.last_name}`}
                                                  secondary={age}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <Box>
                    Brakuje kogoś na liście? <Button onClick={handleOpen} size="small" variant={"contained"}
                                                     endIcon={<Add/>}>Dodaj profil dziecka</Button>
                </Box>
            </Stack>
            <KidCreateDialog open={open} handleClose={handleClose}></KidCreateDialog>
        </React.Fragment>
    );
}