import React from "react";

import QrCode2Icon from '@mui/icons-material/QrCode2';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyIcon from '@mui/icons-material/Key';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SecurityIcon from '@mui/icons-material/Security';
import store from "../store";
import {openQrDialog} from "../features/user/userSlice";

export const showQrCodeDialog = () => {
    store.dispatch(openQrDialog());
}

//style={{fontSize: ""}}
//fontSize="large"

export const navigation = [
    {
        label: "userNav.qrCode",
        icon: <QrCode2Icon  />,
        onClick: showQrCodeDialog,
    },
    {
        label: "userNav.yourAccount",
        icon: <i className="icon-rmg-user" />,
        path: "routes.user",
        children: [
            {
                label: "userNav.dashboard",
                icon: <InfoIcon/>,
                path: "routes.user",
            },
            {
                label: "userNav.yourData",
                icon: <ManageAccountsIcon/>,
                path: "routes.profile",
            },
            {
                label: "userNav.credentials",
                icon: <SecurityIcon/>,
                path: "routes.credentials",
            },
            {
                label: "userNav.yourConsents",
                icon: <HowToRegIcon/>,
                path: "routes.consents",
            },
            {
                label: "userNav.kids",
                icon: <EmojiPeopleIcon/>,
                path: "routes.userKids",
            }
        ]
    },

    {
        label: "userNav.yourCoupons",
        icon: <i className="icon-rmg-coupon"/>,
        path: "routes.userCoupons"
    },
    {
        label: "userNav.insurance",
        icon: <i className="icon-rmg-insurance" />,
        path: "routes.insurance"
    },
    {
        label: "userNav.history",
        icon: <HistoryIcon />,
        path: "routes.userRuns",
        children: [
            {
                label: "userNav.yourStarts",
                icon: <DirectionsRunIcon/>,
                path: "routes.userRuns"
            },
            {
                label: "userNav.yourOrders",
                icon: <ContentPasteIcon/>,
                path: "routes.userOrders"
            },
            {
                label: "userNav.yourPayments",
                icon: <CreditCardIcon/>,
                path: "routes.userPayments"
            }
        ]
    },
    {
        label: "userNav.volunteering",
        icon: <Diversity3Icon/>,
        path: "routes.userVolunteering",
    },
    {
        label: "userNav.logout",
        icon: <PowerSettingsNewIcon/>,
        path: "routes.logout"
    }
];