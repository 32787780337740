import {AddToCart} from "../AddToCart";
import {render, unmountComponentAtNode} from "react-dom";
import React, {createElement, Suspense, useState} from "react";
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ThemeProvider
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AddToCartProductVariants} from "../AddToCartProductVariants";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import {theme} from "../../../lib/theme";


export const AddToCartWrap = ({product}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    if (typeof(product) === "string") {
        product = JSON.parse(product);
    }

    if (product.variants.length === 0) {
        return <AddToCart fullWidth={false}  size="medium" variant="contained" data={[{type: "product", product_id: product.id}]}/>
    }
    const handleClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const variantsForButton = Object.fromEntries(product.variants.map(v => [v.id, v.name]));

    return <>
        <Button variant="contained" fullWidth={false} size="medium" onClick={handleClick} endIcon={<AddShoppingCartIcon/>}>Wybierz wariant</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {t('cart.addToCart')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <AddToCartProductVariants onAdd={handleClose} variants={variantsForButton} product_id={product.id}/>
            </DialogContent>
        </Dialog>
    </>
}
//
const WrappedElement = (props) => {
    return <ThemeProvider theme={theme}>
        <Suspense fallback="...">
            <AddToCartWrap {...props}/>
        </Suspense>
    </ThemeProvider>
}

class AddToCartWrapElement extends HTMLElement {
    connectedCallback() {
        const props = Object.values(this.attributes).map(attribute => [attribute.name, attribute.value])
        render(createElement(WrappedElement, Object.fromEntries(props)), this)
    }

    disconnectedCallback() {
        unmountComponentAtNode(WrappedElement)
    }
}

customElements.define('app-add-to-cart-wrap', AddToCartWrapElement)

export default AddToCartWrapElement