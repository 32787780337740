import React, {createElement, Suspense, useEffect, useState} from 'react';
import {Box, Button, CardMedia, Stack, ThemeProvider, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import KidsShirtSizeSelect from "../KidsShirtSizeSelect";
import {useTranslation} from "react-i18next";
import KidsShirtSizeList from "../KidsShirtSizeList";
import YesNoToggle from "../../YesNoToggle";


const ShirtSelect = ({value, onSelect}) => {
    const {t} = useTranslation();
    const defaultInterest = value !== null && value !== false ? true : value;
    const defaultShirtSize = value !== null && value !== false ? value : null;

    const [interested, setInterested] = useState(defaultInterest);
    const [shirtSize, setShirtSize] = useState(defaultShirtSize);

    const handleChange = (event, newValue) => {
        setInterested(newValue);
    }

    const handleSizeChange = (event, newValue) => {
        setShirtSize(newValue);
    }
    useEffect(() => {
        if (!onSelect) {
            return; // legacy usage
        }
        if (interested === false || interested === null) {
            onSelect(interested);
        } else {
            if (shirtSize === null) {
                onSelect(null);
            } else {
                onSelect(shirtSize);
            }
        }
    }, [interested, shirtSize]);


    return <Stack spacing={2}>
        <Typography>{t('kidShirtSelect.description')}</Typography>
        <Box alignContent="center" textAlign="center">
            <img
                src="https://cdn.runmageddon.pl/2024-07/tshirtkids.jpeg"
                alt="Koszulka Kids"
                style={{
                    width: "360px",
                    borderRadius: "8px",
                    border: "5px solid rgba(255, 222, 0, 0.08)"
            }}
            />
        </Box>
        <YesNoToggle value={interested} onChange={handleChange}></YesNoToggle>
        {interested && <Box sx={{minWidth: "240px"}}>
            <KidsShirtSizeList value={shirtSize} onChange={handleSizeChange} name="shirt_size"/>
        </Box> }
    </Stack>
}

export default ShirtSelect;