import LoginForm from "../../components/Forms/LoginForm";
import {Card, CardContent, Container, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const Login = () => {
    const {t} = useTranslation();
    return <>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

        <Card sx={{maxWidth: 520}} variant="outlined">
            <CardContent>
                <Typography variant="h5" align="center">{t('user.login.login')}</Typography>
                <LoginForm/>
            </CardContent>
        </Card>
        </Container>
    </>
}

export default Login;