import React, {useEffect, useState} from 'react';
import {formatMoney} from "../../../lib/utils";
import {
    Button,
    Card,
    Radio,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Typography,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    DialogActions, Dialog, IconButton, Paper
} from "@mui/material";
import { useTranslation, Trans } from 'react-i18next';
import {client} from "../../../lib/Client";
import InvoiceDialog from "../InvoiceDialog";
import ShippingDialog from "../ShippingDialog";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import PaymentChoice from "../PaymentChoice";

//blik, pkonp, pekao, santander, ing, mbank, paribas, millemium

const defaultPayments = [
    73, //blik
    4, //pkobp - ipko
    36, //pekao
    89, //santander
    38, //ing

    260, // google pay
    262, // apple pay

    1, // mbank
]

const CartPayment = ({cart, updateCart}) => {
    const { t } = useTranslation();
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const toggle = (open) => () => {
        setOpen(open);
    }

    const fetchPayments = () => {
        if (!cart.payment_required) {
            return;
        }
        setLoading(true);
        return client.payments(cart.total.amount, cart.total.currency.code).then((response) => {
            setPayments(response.data.channels);
            setLoading(false);
        }).catch((error) => {
            console.log('There was an error fetching payments', error)
        });
    }

    const handleChange = (id) => () => {
        setLoading(true)
        updateCart({payment: id}).then(() => {
            setLoading(false);
            setOpen(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchPayments();
    }, []);

    const groups = new Map();
    for (const item of payments) {
        if(!groups.has(item.group)){
            groups.set(item.group, item.group_name);
        }
    }

    if (!cart.payment_required) {
        return <></>
    }

    const cartPayment = !cart.payment ? '' : cart.payment.toString();
    const displayPaymentIds = defaultPayments
    let payment = null;

    if (cart.payment && payments.length) {
        payment = payments.filter((item) => item.id.toString() == cart.payment.toString()).pop();
    }

    const displayPayments = payments.filter((item) => displayPaymentIds.includes(item.id));
    displayPayments.sort((a, b) => displayPaymentIds.indexOf(a.id) > displayPaymentIds.indexOf(b.id) ? 1 : -1)

    return <React.Fragment>
        <Dialog open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle>
                {t('cart.choosePayment')}
                <IconButton
                    aria-label="close"
                    onClick={toggle(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {Array.from(groups).map(group => <div key={group[0]}>
                    <Typography variant="h5">{group[1]}</Typography>
                    <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            p: 1,
                        },
                    }}
                    >
                    {payments.filter(item => item.group === group[0]).map((item, index) => {
                        return <PaymentChoice
                            payment={item}
                            key={item.id}
                            selected={cartPayment === item.id.toString()}
                            onClick={handleChange(item.id)}
                        />
                    })}
                </Box>
                </div>)}
            </DialogContent>
        </Dialog>
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    {t('cart.choosePayment')} {loading && <CircularProgress size="1em"/>}
                </Typography>
                {payment !== null &&<Box sx={{mt: 2, mb: 2}}>
                        <Typography sx={{mb:1}}>{payment.group_name}: {payment.name}</Typography>
                        <PaymentChoice payment={payment} onClick={toggle(true)}/>
                </Box> }
                {payment === null && <Box
                    sx={{
                        mb: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            p: 1,
                        },
                    }}
                >
                    {displayPayments.map(item =><PaymentChoice size={64} key={item.id} payment={item} onClick={handleChange(item.id)}/>)}
                </Box> }
                <Button variant="outlined" onClick={toggle(true)}>Wybierz inną formę płatności</Button>
            </CardContent>
        </Card>
    </React.Fragment>
}

export default CartPayment

