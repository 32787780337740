import React, {useState} from "react";
import {
    Box,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import {MuiTelInput} from "mui-tel-input";

const ShippingDialog = ({cart, open, handleClose, updateCart}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    let defaultValues = {}
    if (!Array.isArray(cart.shipping_data)) {
        Object.assign(defaultValues, cart.shipping_data);
    }
    const [shippingData, setShippingData] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const handleDataChange = (field) => (event) => {
        const newData = {}
        Object.assign(newData, shippingData);
        newData[field] = event.target.value;
        setShippingData(newData);
    }
    const handlePhoneNumberChange = (value) => {
        const newData = {}
        Object.assign(newData, shippingData);
        newData["phone_number"] = value;
        setShippingData(newData);
    }
    ["first_name", "last_name", "company", "street", "house_number", "flat_number", "zip_code", "town", "phone_number"].map((item) =>  {
        if (typeof shippingData[item] === "undefined" || shippingData[item] === null) {
            shippingData[item] = ""
        }
    })
    if (shippingData["phone_number"] === "") {
        // shippingData["phone_number"] = "+48"
    }
    const handleSubmit = (event) => {
        setLoading(true);

        const data = {
            shipping_data: shippingData
        }

        updateCart(data).then(() => {
            setLoading(false);
            handleClose();
            setErrors({});
        }).catch((error) => {
            setLoading(false);
            if (error.response) {
                const error_messages = {};
                Object.keys(error.response.data["errors"]).forEach((key, index) => {
                    error_messages[key] = Object.values(error.response.data["errors"][key]).join(". ")
                });
                setErrors(error_messages);
            } else {
                setErrors({});
            }
        })
    }

    return (
        <Dialog open={open}>
            <DialogTitle>{t('shipping.shippingAddress')}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, marginRight:1, }}>
                        <TextField
                            error={"first_name" in errors}
                            helperText={"first_name" in errors ? errors["first_name"] : ""}
                            label={t("shipping.firstName")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("first_name")}
                            value={shippingData["first_name"]}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, }}>
                        <TextField
                            error={"last_name" in errors}
                            helperText={"last_name" in errors ? errors["last_name"] : ""}
                            label={t("shipping.lastName")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("last_name")}
                            value={shippingData["last_name"]}
                        />
                    </Box>
                </Box>
                <TextField
                    error={"company" in errors}
                    helperText={"company" in errors ? errors["company"] : ""}
                    label={t("shipping.company")}
                    fullWidth
                    variant="outlined"
                    onChange={handleDataChange("company")}
                    value={shippingData["company"]}
                />
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, marginRight:1, }}>
                        <TextField
                            error={"street" in errors}
                            helperText={"street" in errors ? errors["street"] : ""}
                            label={t("shipping.street")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("street")}
                            value={shippingData["street"]}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 0, width: '15ch', marginRight:1}}>
                        <TextField
                            error={"house_number" in errors}
                            helperText={"house_number" in errors ? errors["house_number"] : ""}
                            label={t("shipping.houseNumber")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("house_number")}
                            value={shippingData["house_number"]}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 0, width: '15ch', }}>
                        <TextField
                            error={"flat_number" in errors}
                            helperText={"flat_number" in errors ? errors["flat_number"] : ""}
                            label={t("shipping.flatNumber")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("flat_number")}
                            value={shippingData["flat_number"]}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexGrow: 0, width: '15ch', marginRight:1, }}>
                        <TextField
                            error={"zip_code" in errors}
                            helperText={"zip_code" in errors ? errors["zip_code"] : ""}
                            label={t("shipping.zipCode")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("zip_code")}
                            value={shippingData["zip_code"]}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, }}>
                        <TextField
                            error={"town" in errors}
                            helperText={"town" in errors ? errors["town"] : ""}
                            label={t("shipping.town")}
                            variant="outlined"
                            fullWidth
                            onChange={handleDataChange("town")}
                            value={shippingData["town"]}
                        />
                    </Box>
                </Box>
                <MuiTelInput
                    error={"phone_number" in errors}
                    helperText={"phone_number" in errors ? errors["phone_number"] : ""}
                    label={t("shipping.phoneNumber")}
                    onChange={handlePhoneNumberChange}
                    value={shippingData["phone_number"]}
                    fullWidth
                    variant="outlined"
                    langOfCountryName={t('locale')}
                    preferredCountries={['PL', 'GB', 'UA', 'CZ', 'SK', 'NO', 'SE']}
                    defaultCountry={'PL'}
                    forceCallingCode
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"}>{t('cancel')}</Button>
                <Button onClick={handleSubmit} startIcon={loading && <CircularProgress size="1em"/>} endIcon={<SendIcon/>}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShippingDialog