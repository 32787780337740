import React from "react";
import ReactDOM from "react-dom";
import InsuranceHTMLElement from "./components/Insurance";
import InsuranceLandingPageHTMLElement from "./components/InsuranceLandingPage";
import AddToCartStarterPackShirtElement from "./components/Order/AddToCartStarterPackShirt";
import AddToCartProductElement from "./components/Order/AddToCartProduct";
import AddToCartProductVariantsElement from "./components/Order/AddToCartProductVariants";
import ErrorsHTMLElement from "./components/Errors";
import StoreFiltersElements from "./components/Store/Filters";
import AddToCartWrapElement from "./components/Order/AddToCartWrap";
import {FileUpload} from './components/FileUpload';
import {MuiTelInput} from "mui-tel-input";
import {WrappedTelInput} from "./components/Common/TelInput";
import {showQrCodeDialog} from "./lib/user";
import {mountReactElement} from "./lib/utils";
import {AddToCart} from "./components/Order/AddToCart";
import ShirtSelect from "./components/SignUp/ShirtSelect";
import AddToCartShirt from "./components/Order/AddToCartShirt";
import EditAvatar from "./components/User/EditAvatar";

document.addEventListener('DOMContentLoaded', () => {
    const uploadInputs = document.getElementsByClassName("file-upload");
    for (let i = 0; i < uploadInputs.length; i++) {
        let container = uploadInputs[i].parentNode;
        ReactDOM.render(<FileUpload name={uploadInputs[i].getAttribute("name")}/>, container);
    }
});

// horrible hack for mui popovers and modals
document.addEventListener('DOMContentLoaded', () => {
    const body = document.getElementsByTagName('body')[0];
    const header = document.getElementsByTagName('header')[0];
    if (!header) {
        return;
    }

    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
            header.style.right = body.style.paddingRight;
        });
    });

    observer.observe(body, {attributes: true, attributeFilter: ['style']});
});


document.addEventListener('DOMContentLoaded', () => {
    const telInputs = document.querySelectorAll("input[type=tel]");
    for (let i = 0; i < telInputs.length; i++) {
        let container = document.createElement("div");
        let value = telInputs[i].value;
        let name = telInputs[i].getAttribute("name");
        telInputs[i].replaceWith(container)
        ReactDOM.render(<WrappedTelInput
            name={name}
            value={value}
        />, container);
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const btn = document.getElementById("qrcode-legacy-button");
    const onClick = (e) => {
        e.preventDefault();
        showQrCodeDialog();
    }
    if (btn) {
        btn.addEventListener("click", onClick);
    }
});

mountReactElement(AddToCart, 'app-add-to-cart');
mountReactElement(ShirtSelect, 'app-shirt-select');
mountReactElement(AddToCartShirt, 'app-add-to-cart-shirt');
mountReactElement(EditAvatar, 'app-edit-avatar');